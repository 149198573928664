import { FormikContextType, useFormikContext } from 'formik';
import React from 'react';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps } from 'hoc/withFormikField';

type FormikFieldNameOptionalProps = PartialBy<FormikFieldProps, 'name'>;
export type FormikCustomRenderProps = FormikContextType<any> & FormikFieldNameOptionalProps;
export type FormikCustomProps = FormikFieldNameOptionalProps & {
  render?: (props: FormikCustomRenderProps) => React.ReactNode;
  name?: string;
  children?: React.ReactNode | ((props: FormikCustomRenderProps) => React.ReactNode);
};

export type CustomFieldProps = any;

export type FormikCustomWithFieldLayoutProps = FormikCustomProps & FieldLayoutProps;
export type FormikCustomFieldProps = ({ withFieldLayout?: true } & FormikCustomWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikCustomProps);

export const CustomField = withFieldLayout<CustomFieldProps>(({
  render,
  children,
  ...props
}: CustomFieldProps) => {
  if (children) {
    if (typeof children === 'function') {
      return <React.Fragment>{children(props)}</React.Fragment>;
    }
    return <React.Fragment>{children}</React.Fragment>;
  }
  return render ? <React.Fragment>{render(props)}</React.Fragment> : <div/>;
});

CustomField.defaultProps = {
  fieldContentWidth: 'auto'
};

export const FormikCustom: React.FC<FormikCustomProps> = ({
  render,
  children,
  ...props
}: FormikCustomProps) => {

  const formikBag = useFormikContext();
  const newProps = {
    ...props,
    ...formikBag
  };

  if (children) {
    if (typeof children === 'function') {
      return <React.Fragment>{children(newProps)}</React.Fragment>;
    }
    return <React.Fragment>{children}</React.Fragment>;
  }
  return render ? <React.Fragment>{render(newProps)}</React.Fragment> : <div/>;
};
export const FormikCustomWithFieldLayout = withFieldLayout<FormikCustomWithFieldLayoutProps>(FormikCustom);

export const FormikCustomField: React.FC<FormikCustomFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikCustom {...props} />;
  } else {
    return <FormikCustomWithFieldLayout {...props} />;
  }
};

FormikCustomField.defaultProps = {
  fieldContentWidth: 'auto'
};
