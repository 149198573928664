import { Creative, OutdoorType } from 'core/creative/Creative';
import _ from 'lodash';
import { BasicFormProps } from './BasicFormProps';
import { EdiMaxFormModel } from './EdiMaxFormModel';
import PICForm from './PICForm';
import { validateMinMax } from 'utils/ValidateUtils';
import i18n from 'i18n';

export type PICFormProps = {
  readonly model: PICFormModel;
} & BasicFormProps;

export class PICFormModel extends EdiMaxFormModel {

  constructor (
    public minDuration: number,
    public maxDuration: number
  ) {
    super();
  }

  getFormContent = () => {
    return PICForm;
  }

  getInitTypeProperties () {
    return {
      outdoorType: OutdoorType.VIDEO,
      temperatureEnable: false,
      temperatureRange: [25, 30],
      duration: this.maxDuration,
      useAudio: false
    };
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues', 'bannerExtra', 'duration', 'outdoorType']);
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const imageUrl = _.get(creativeValue, 'imageUrl');
    const videoUrl = _.get(creativeValue, 'videoUrl');
    const htmlUrl = _.get(creativeValue, 'htmlUrl');
    const audioUrl = _.get(creativeValue, 'audioUrl');
    const mediaData = imageUrl || videoUrl || htmlUrl || audioUrl ? _.omitBy({
      image: imageUrl ? {
        url: imageUrl
      } : undefined,
      video: videoUrl ? {
        url: videoUrl
      } : undefined,
      html: htmlUrl ? {
        url: htmlUrl
      } : undefined,
      audio: audioUrl ? {
        url: audioUrl
      } : undefined
    }, _.isEmpty) : undefined;
    const useTempControl = _.get(creative.basic, 'bannerExtra.useTempControl', false);
    const minTemp = useTempControl ? _.get(creative.basic, 'bannerExtra.minTemp') : 25;
    const maxTemp = useTempControl ? _.get(creative.basic, 'bannerExtra.maxTemp') : 30;
    const useAudio = _.get(creative.basic, 'bannerExtra.useAudio', false);
    return {
      basic: {
        ...creativeBasic,
        typeProperties: {
          outdoorType: _.get(creative.basic, 'outdoorType', OutdoorType.VIDEO),
          duration: _.get(creative.basic, 'duration', this.maxDuration),
          temperatureEnable: useTempControl,
          temperatureRange: [minTemp, maxTemp],
          useAudio
        },
        medias: mediaData
      },
      limitations: creative.limitations
    };
  }

  validateVideo (outdoorType, fileData) {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const duration = _.floor(fileData.duration);
    const error = validateMinMax(duration, this.minDuration, this.maxDuration);
    if (error) {
      return i18n.t<string>('picShorFormModel.errors.videoDuration', { min: this.minDuration, max: this.maxDuration });
    }

    return super.validateVideo(outdoorType, fileData);
  }

  async validateAudio (fileData) {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }
    const duration = _.floor(fileData.duration);
    const error = validateMinMax(duration, this.minDuration, this.maxDuration);
    if (error) {
      return i18n.t<string>('picShorFormModel.errors.audioDuration', { min: this.minDuration, max: this.maxDuration });
    }

    return super.validateAudio(fileData);
  }
}
