import { SelectOptions } from 'components/common/commonType';
import { CustomField } from 'components/common/form/field/CustomField';
import { Page } from 'components/Page/Page';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import { defaultTo, LoDashStatic } from 'lodash';
import { FormikField } from 'components/common/form/field/FormikField';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useFormikContext } from 'formik';
import { ALL_SELECT_VALUE, AUTO_UPDATE_VALUE, SpaceProductGroupFormValue } from 'core/limitation/ProductGroup';
import { DefaultProductGroupManager, AttributesInventorySetting } from 'core/limitation/ProductGroupManager';
import { DefaultInventoryModel, Inventory } from 'containers/Limitations/Inventory';
import { DefaultSelectComponentModel, SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { DEFAULT_INVENTORY } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import LimitationSet from 'components/CampaignLimitation/LimitationSet/LimitationSet';
import styles from './productGroupForm.module.scss';
import { Button } from 'react-bootstrap';
import { DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { useCoreContext } from 'contexts/coreContext';
import { isSystemAdmin } from 'helper/ActorHelper';
import { PMaxCore } from 'core';

const productGroupManager = new DefaultProductGroupManager();
const adRequestSourceManager = new DefaultAdRequestSourceManager();
export const FilterSpaceSection: React.FC<{
  disable: boolean;
  adFormatAndChannelOptions: SelectOptions[];
  spaceAttributes: {
    [adFormat: string]: { [attribute: string]: SelectOptions[] }
  };
  setAdFormatAndChannelOptions: Dispatch<SetStateAction<SelectOptions[]>>;
  setSpaceAttributes: Dispatch<SetStateAction<{
    [adFormat: string]: { [attribute: string]: SelectOptions[] }
  }>>;
  onSpacesOptionsFetched: (spaceOptions: SelectOptions[]) => void;
}> = ({
  disable,
  spaceAttributes,
  adFormatAndChannelOptions,
  setAdFormatAndChannelOptions,
  setSpaceAttributes,
  onSpacesOptionsFetched
}) => {

  const { values, setFieldValue } = useFormikContext<SpaceProductGroupFormValue>();
  const [needFetchSpacesOptions, setNeedFetchSpacesOptions] = useState<boolean>(false);
  const adFormat = defaultTo(values.adFormat, '');
  const channel = defaultTo(values.channel, '');
  const attributes = defaultTo(values.attributes, {});
  const inventorySettings = useMemo(() => productGroupManager.getSpaceAttributesInventorySettings(adFormat, channel, spaceAttributes), [adFormat, channel, spaceAttributes]);

  const [inventory, setInventory] = useState<string>(DEFAULT_INVENTORY);
  const { loading, callAPIs } = useCallAPI();

  const core = useCoreContext() as PMaxCore;
  const isSysAdmin = isSystemAdmin(core.authenticationManager.actor);
  const addonFeatureManager = core.addonFeatureManager;

  useEffect(() => {
    if (adFormatAndChannelOptions.length === 0) {
      callAPIs([() => productGroupManager.getProductGroupValidAdFormatAndChannel(isSysAdmin, addonFeatureManager)], (adFormatAndChannelOptions: SelectOptions[]) => {
        setAdFormatAndChannelOptions(adFormatAndChannelOptions);
      });
    }
  }, [adFormatAndChannelOptions, isSysAdmin, addonFeatureManager, callAPIs, setAdFormatAndChannelOptions]);

  useEffect(() => {
    needFetchSpacesOptions && onSpacesOptionsFetched([]);
  }, [needFetchSpacesOptions, onSpacesOptionsFetched]);

  useEffect(() => {
    if (adFormat === '') {
      return;
    }
    if (!spaceAttributes[adFormat]) {
      callAPIs([() => adRequestSourceManager.getSpaceAttributes(adFormat)], attributes => {
        setSpaceAttributes(prev => ({
          ...prev,
          [adFormat]: attributes
        }));
      });
    }
  }, [adFormat, spaceAttributes, callAPIs, setSpaceAttributes]);

  const adFormatOptions = useMemo(() => {
    return adFormatAndChannelOptions.map(option => ({ label: option.label, value: option.value }));
  }, [adFormatAndChannelOptions]);

  const channelOptions = useMemo(() => {
    const target = adFormatAndChannelOptions.find(option => option.value === adFormat);
    return target ? defaultTo(target.options, []) : [];
  }, [adFormat, adFormatAndChannelOptions]);

  const updateAttributes = useCallback((newValue: { [key: string]: string[] }) => {
    setNeedFetchSpacesOptions(true);
    setFieldValue('attributes', newValue);
  }, [setFieldValue]);

  const resetAttributes = useCallback(() => {
    setInventory(DEFAULT_INVENTORY);
    updateAttributes({});
  }, [updateAttributes]);

  const onAdFormatChange = useCallback((adFormat: string) => {
    const target = adFormatAndChannelOptions.find(option => option.value === adFormat);
    const supportedChannels = target ? defaultTo(target.options, []) : [];
    const isSupport = supportedChannels.find(option => option.value === channel) !== undefined;
    if (!isSupport) {
      setFieldValue('channel', supportedChannels.length > 0 ? supportedChannels[0].value : '');
    }
    resetAttributes();
  }, [channel, adFormatAndChannelOptions, setFieldValue, resetAttributes]);

  const onDeleteAttributes = useCallback((type: string) => {
    let limitationdelete = attributes[type];
    if (limitationdelete) {
      delete attributes[type];
      updateAttributes({ ...attributes });
    }
  }, [attributes, updateAttributes]);

  const onAttributesChange = useCallback((type: string, newValue: SelectOptions[] | LoDashStatic) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue.length === 0) {
      onDeleteAttributes(type);
      return;
    }

    updateAttributes({
      ...attributes,
      [type]: newValue.map(option => option.value.toString())
    });
  }, [attributes, updateAttributes, onDeleteAttributes]);

  const querySpaceOptionsByFilters = useCallback(() => {
    setNeedFetchSpacesOptions(false);
    callAPIs([() => adRequestSourceManager.getSpacesWithConditions(attributes, adFormat, channel)], (options) => {
      onSpacesOptionsFetched([
        { label: i18n.t<string>('productGroupForm.labels.selectAllSpace'), value: ALL_SELECT_VALUE },
        { label: i18n.t<string>('productGroupForm.labels.conditionSpace'), value: AUTO_UPDATE_VALUE },
        ...options
      ]);
    });
  }, [adFormat, attributes, channel, callAPIs, onSpacesOptionsFetched]);

  const inventoryModel = useMemo(() => {
    return new DefaultInventoryModel(
      inventory,
      inventorySettings,
      {},
      'productGroupForm.labels.attributes',
      newInventory => setInventory(newInventory)
    );
  }, [inventory, inventorySettings]);

  const renderInventoryComponent = useCallback((inventorySettings: AttributesInventorySetting[]) => {
    return inventorySettings.map(inventorySetting => {
      const {
        name,
        singleSelect,
        itemSetting,
        searchPlaceholder
      } = inventorySetting;
      const value = defaultTo(attributes[name], []).map(value => ({
        label: i18n.t<string>(`productGroup.space.attributes.${value}`),
        value
      }));
      const model = new DefaultSelectComponentModel(
        name,
        singleSelect,
        itemSetting,
        i18n.t<string>(searchPlaceholder),
        newValue => onAttributesChange(name, newValue),
        [],
        value,
        undefined,
        disable
      );

      return <SelectComponent key={name} model={model} />;
    });
  }, [disable, attributes, onAttributesChange]);

  const renderFilterSet = useCallback((inventorySettings: AttributesInventorySetting[]) => {
    const ignoreDefault = ({ name }: AttributesInventorySetting) => name !== DEFAULT_INVENTORY;
    return inventorySettings.filter(ignoreDefault).map(({ name, title }) => {
      const value: SelectOptions[] = defaultTo(attributes[name], []).map(value => ({
        label: i18n.t<string>(`productGroup.space.attributes.${value}`),
        value
      }));
      const handleAdd = () => setInventory(name);
      const onChange = newValue => onAttributesChange(name, newValue);
      const onDelete = (_, type) => onDeleteAttributes(type);
      return (
        <LimitationSet
          key={name}
          value={value}
          title={title}
          type={name}
          disable={disable}
          add={handleAdd}
          onChange={onChange}
          deleteLimitation={onDelete}
        />
      );
    });
  }, [disable, attributes, onDeleteAttributes, onAttributesChange]);

  return (
    <Page.Section
      title={i18n.t<string>('productGroupForm.title.filterSection')}
      inlineTitle
      withShadow={false}
    >
      {loading && <LoadingIndicator />}
      <FormikField.Select
        labelColSm={2}
        inputColSm={10}
        label={i18n.t<string>('productGroupForm.labels.adFormat')}
        name='adFormat'
        simpleValue
        options={adFormatOptions}
        onChange={onAdFormatChange}
        disabled={disable}
      />
      <FormikField.Select
        labelColSm={2}
        inputColSm={10}
        label={i18n.t<string>('productGroupForm.labels.channel')}
        name='channel'
        simpleValue
        options={channelOptions}
        onChange={resetAttributes}
        disabled={disable}
      />
      <CustomField
        labelColSm={2}
        inputColSm={10}
        label={i18n.t<string>('productGroupForm.labels.attributes')}
        name={'attributes'}
      >
        <div style={{ display: 'flex' }}>
          <div>
            <Inventory width={438} height={338} sidebarWidth={160} model={inventoryModel}>
              {renderInventoryComponent(inventorySettings)}
            </Inventory>
          </div>
          <div className={styles.selectedFilters}>{renderFilterSet(inventorySettings)}</div>
        </div>
      </CustomField>
      <CustomField
        labelColSm={2}
        inputColSm={10}
        label=''
        name='filterButton'
      >
        <Button style={{ marginTop: '24px' }} variant='primary' size='sm' disabled={disable} onClick={querySpaceOptionsByFilters}>
          {i18n.t<string>('productGroupForm.buttons.filterSpace')}
        </Button>
      </CustomField>
    </Page.Section>
  );
};
