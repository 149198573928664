
import { CreativeType } from 'core/creative/Creative';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { Limitation } from 'core/limitation/Limitation';

export type RtbCampaign = {
  basic: RtbCampaignBasic;
  limitations: { [type: string]: Limitation[] };
};

export type RtbCampaignBasic = {
  id?: number;
  name: string;
  advertiserId: number;
  orderId: number;
  budget: number;
  startDate: string;
  endDate: string;
  createDate?: string;
  priceModel: RtbCampaignPlanType;
  orderPrice?: number;
  optimize: L2ObjectOptimizationGoal;
  bidPrice?: number;
  creativeDeliverType: CreativeDeliverType;
  deliverType: DeliverType;
  state?: CampaignState;
  effectiveStatus?: string;
  spents?: number;
  currencyRate?: number;
  olapActualSpent?: number;
  olapExpectSpent?: number;
  expectedSpent: number;
  impres?: number;
  clicks?: number;
  uuCount?: number;
  dailyTargetBudget: number | null;
  frequency?: CampaignFrequency;
  dayPart?: { [key: string]: string[] | number[] | string };
  isSelfBuyOnly?: boolean;
  checkpoints: CheckPoint[];
  dealIds?: string[];
  enableMonitor?: boolean;
  adType?: AdType;
  videoAdViewObjective?: VideoAdViewObjectiveType;
  conversionTracking?: ConversionTracking;
  viewTrackingCodes?: ViewTrackingCode[];
  videoProgressTrackingCode?: VideoProgressTrackingCode;
  additionalInfo?: AdditionalInfo;
  tags: Array<string>;
  viewable?: number;
  convs?: number;
  adView?: number;
  convTrackEvent?: string;
  orderPriceEnable: boolean;
  retailType?: string;
  bindings?: {
    bindingId: string,
    active: boolean
  },
  isDraft?: boolean;
  draftId?: number;
  magnificationRatio: number;
  l2ObjectId?: number;
};

export type AdditionalInfo = {
  creativeAmount: CreativeAmount,
  limitations: { [key: string]: object[] },
  viewTrackingSize?: number,
  conversionTracking?: AdditionalInfoConversionTracking,
  videoProgressTrackingOffset?: number
};

export type CreativeAmount = {
  bindingCount: number,
  enableCount: number
};

export type AdditionalInfoConversionTracking = {
  conversionType: string,
  id: number,
  name: string,
  conversionId: string
};

export type ConversionTracking = {
  label: string,
  value: string
};

export type ViewTrackingCode = {
  originTrackingCode: string,
  trackingCode: string,
  trackingType: string
};

export type VideoProgressTrackingCode = {
  event: string,
  offset: number,
  code: string
};

export type CampaignFrequency = {
  maxFrequency: number;
  intervalDays: number;
};

export type CheckPoint = {
  target: number;
  time: string;
  utime: string;
};

export type VideoAdViewObjectiveType = {
  event: VideoAdViewObjective.PROGRESS | VideoAdMetricEvent;
  offset?: number;
  videoAdEvent?: VideoAdViewObjective;
};

export enum AdType {
  DISPLAY = 'DISPLAY',
  VIDEO = 'VIDEO',
  COMBO = 'COMBO',
  EDIMAX = 'EDIMAX',
  PIC_SHORT = 'PIC_SHORT',
  PIC_LONG = 'PIC_LONG',
  THIRD_PARTY = 'THIRD_PARTY',
  THIRD_PARTY_BOTTOM = 'THIRD_PARTY_BOTTOM',
  THIRD_PARTY_RECTANGLE = 'THIRD_PARTY_RECTANGLE',
  UNKNOW = 'UNKNOW',
  ONE_FOR_ALL_DISPLAY = 'ONE_FOR_ALL_DISPLAY',
  RETAIL = 'RETAIL',
  PILOT_TV = 'PILOT_TV',
  CTV = 'CTV',
  MESSAGE = 'MESSAGE'
}

export enum RtbLegacyOptimize {
  CPC = 'CPC',
  CPV = 'CPV',
  CPM = 'CPM',
  VCPM = 'vCPM'
}

export enum RtbOptimize {
  REACH = 'REACH',
  VIDEO_VIEWS = 'VIDEO_VIEWS',
  IMPRESSIONS = 'IMPRESSIONS',
  CLICKS = 'CLICKS',
  SALES = 'SALES',
  LINK_CLICKS = 'LINK_CLICKS',
  OFFSITE_CONVERSIONS = 'OFFSITE_CONVERSIONS'
}

export enum RtbCampaignPlanType {
  RS = 'rs',
  FCPC = 'fcpc',
  FCPV = 'fcpv',
  FCPM = 'fcpm',
  FVCPM = 'fvcpm',
  DCPM = 'dcpm'
  // RB = 'rb'
}

export enum CreativeDeliverType {
  OPTIMIZE = 1,
  AVERAGE = 2
}

export enum CampaignState {
  ACTIVATE = 1,
  DEACTIVATE = 2,
  DELETE = 3,
  STOPPING = 4
}

export enum DailyBudgetPlan {
  SCHEDULE,
  DAILY
}

export enum VideoAdViewObjective {
  DEFAULT = 'defualt',
  PROGRESS = 'progress',
  METRIC = 'metric'
}

export enum VideoAdMetricEvent {
  FIRSTQUARTILE = 'firstQuartile',
  MIDPOINT = 'midpoint',
  // THIRDQUARTILE = 'thirdQuartile',
  COMPLETE = 'complete'
}

export enum DeliverType {
  STANDARD = 1,
  ACCELERATED = 2
}

export enum TrackEvent {
  CLICK = 'click',
  VIEW = 'view'
}

export const AD_TYPE_MAP_CREATIVE_TYPE = {
  [AdType.ONE_FOR_ALL_DISPLAY]: [CreativeType.ONE_FOR_ALL_DISPLAY, CreativeType.IMAGE],
  [AdType.DISPLAY]: [CreativeType.IMAGE, CreativeType.NATIVE, CreativeType.HTML5],
  [AdType.VIDEO]: [CreativeType.VIDEO],
  [AdType.THIRD_PARTY]: [CreativeType.THIRDPARTY, CreativeType.HTML5],
  [AdType.THIRD_PARTY_BOTTOM]: [CreativeType.THIRDPARTY, CreativeType.HTML5, CreativeType.CUSTOM_BOTTOM],
  [AdType.THIRD_PARTY_RECTANGLE]: [CreativeType.THIRDPARTY, CreativeType.HTML5, CreativeType.CUSTOM_RECTANGLE],
  [AdType.EDIMAX]: [CreativeType.EDIMAX],
  [AdType.PIC_SHORT]: [CreativeType.PIC_SHORT],
  [AdType.PIC_LONG]: [CreativeType.PIC_LONG],
  [AdType.COMBO]: [CreativeType.COMBO, CreativeType.HTML5],
  [AdType.RETAIL]: [CreativeType.RETAIL_RICH_MEDIA_PRODUCT, CreativeType.RETAIL_NATIVE_PRODUCT],
  [AdType.PILOT_TV]: [CreativeType.PILOT_TV],
  [AdType.CTV]: [CreativeType.CTV]
};

export const ALLOW_VIEW_CONVERT_AD_TYPE = [AdType.THIRD_PARTY, AdType.THIRD_PARTY_BOTTOM, AdType.THIRD_PARTY_RECTANGLE, AdType.VIDEO];

export const RTBCAMPAIGN_DEFAULT_AGE_MIN = 13;
export const RTBCAMPAIGN_DEFAULT_AGE_MAX = 65;
