import { getCommonColumnData } from './CreativeListCommonColumnData';
import { CreativeListRecord, OutdoorType } from 'core/creative/Creative';
import i18n from 'i18n';
import _ from 'lodash';
import { EdiMaxCreativeListDataProvider } from './EdiMaxCreativeListDataProvider';

enum COLUMNS {
  OUTDOOR_TYPE = 'outdoorType',
  TEMPERATURE = 'temperature',
  DURATION = 'duration'
}

export class PICCreativeListDataProvider extends EdiMaxCreativeListDataProvider {

  getColumns () {
    const columns = super.getColumns();
    const durationColumn = columns.find(column => column.dataField === COLUMNS.DURATION);
    if (durationColumn) {
      durationColumn.formatter = duration => `${duration} ${i18n.t<string>('common.units.seconds')}`;
    }
    console.log(columns);
    return columns;
  }

  getViewModel (creative: CreativeListRecord) {
    const useTempControl = _.get(creative, 'bannerExtra.useTempControl', false);
    const minTemp = _.get(creative, 'bannerExtra.minTemp', 25);
    const maxTemp = _.get(creative, 'bannerExtra.maxTemp', 30);
    const outdoorType = _.get(creative, 'outdoorType', OutdoorType.VIDEO);
    const duration = _.get(creative, 'duration', 30);
    return {
      ...getCommonColumnData(creative),
      [COLUMNS.OUTDOOR_TYPE]: outdoorType,
      [COLUMNS.TEMPERATURE]: useTempControl ? `${minTemp}°C - ${maxTemp}°C` : i18n.t<string>('creativeList.labels.none'),
      [COLUMNS.DURATION]: duration
    };
  }
}
