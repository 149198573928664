import { SelectOptions } from 'components/common/commonType';
import config from 'config';
import { AddonFeatureManager, Currency, LocaleMeta } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import {
  MIN_HOUR,
  MessageCampaignBasic,
  MessageCampaignPlanType
} from 'core/messageCampaign/MessageCampaign';
import { DefaultMessageCampaignManager } from 'core/messageCampaign/MessageCampaignManager';
import { Order } from 'core/order/Order';
import { AdType, CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { getPriceValue } from 'helper/CurrencyHelper';
import i18n from 'i18n';
import _ from 'lodash';
import moment from 'moment';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';
import {
  FireableUpdateEventListener,
  UpdateEventListener
} from 'utils/UpdateEventListener';
import { DefaultMessageCampaignBasicFormValidator, MessageCampaignBasicFormValidator } from './MessageCampaignBasicFormValidator';

export const MESSAGE_MAX_LENGTH = 134;
export const CLICK_URL_MACRO = `{{ShortenUrl}}`;

export type ClickUrlInputModalData = {
  title: string;
  dismiss: () => void;
  onConfirm: () => void;
  onCancel: () => void;
};

export interface MessageCampaignBasicFormModel {
  readonly actionType: string;
  readonly l1Object: L1Object | undefined;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly priceModelOptions: SelectOptions[];
  readonly optimizeOptions: SelectOptions[];
  readonly campaignBasic: MessageCampaignBasic;
  canEditOptimize: (priceModel: MessageCampaignPlanType) => boolean;
  readonly orderName: string;
  readonly agencyProfit?: string;
  readonly currency: string;
  readonly budgetMinimum: number;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
  readonly canEditEnableMonitor: boolean;
  readonly minDate: string;
  readonly maxDate: string;
  readonly defaultPriceModel: MessageCampaignPlanType;
  readonly campaignAdType: AdType;
  readonly showOptimizeSection: boolean;
  readonly availablePriceModel: MessageCampaignPlanType[];
  readonly formikValue?: any;
  readonly optimizeAddonEnable: boolean;
  getBidPriceFloorData (): any;
  getCurrentPriceModel (): MessageCampaignPlanType;
  setCurrentPriceModel (planType: MessageCampaignPlanType);
  getDefaultOptimizeType: (planType: MessageCampaignPlanType) => L2ObjectOptimizationGoal | undefined;
  getRemainBudget: (campaignBudget: number) => number;
  getCampaignTotalDay: (startDate: string, endDate: string) => number;
  state: MessageCampaignBasicFormState;
  readonly event: UpdateEventListener<MessageCampaignBasicFormModel>;
  onPriceModelChangeCallback: (type: MessageCampaignPlanType, campaignBasic: MessageCampaignBasic) => void;
  validate: (campaignBasic: any, order: Order, localeMeta?: LocaleMeta) => any;
  onUnmount: (handler?: number) => void;
  setFormikValue: (value: any) => void;
  computeRemainCharacters: (message: string | undefined, clickUrl: string | undefined) => number;
  setClickUrlInputModalData: (data: ClickUrlInputModalData | undefined) => void;
  fetchInvalidKeywords: (message: string) => Promise<void>;
  getInvalidMessageHint: () => string | undefined;
}

export type MessageCampaignBasicFormProps = {
  readonly model: MessageCampaignBasicFormModel;
};

export type MessageCampaignBasicFormState = {
  clickUrlInputModalData: ClickUrlInputModalData | undefined;
  validatingMessageContent: boolean;
  invalidKeywordsMap: {[key: number]: string[]};
};

export type MessageCampaignBasicFormModelConstructorParams = [
  any,
  MessageCampaignBasic,
  Order,
  L1Object | undefined,
  AddonFeatureManager,
  (type: MessageCampaignPlanType, campaignBasic: MessageCampaignBasic) => void,
  MessageCampaignBasic[]?,
];

const getMockClickURL = () => {
  if (!config.shortenUrlLength) {
    return '';
  }
  let result = '';
  for (let i = 0; i < config.shortenUrlLength; i++) {
    result += 'a';
  }
  return result;
};
export abstract class DefaultMessageCampaignBasicFormModel
  implements MessageCampaignBasicFormModel {
  event: FireableUpdateEventListener<MessageCampaignBasicFormModel>;
  currentPriceModel: MessageCampaignPlanType;
  validator: MessageCampaignBasicFormValidator;
  messageCampaignManager = new DefaultMessageCampaignManager();
  retailerOptions?: SelectOptions[] = undefined;
  formikValue?: any;
  optimizeAddonEnable: boolean;
  clickUrlInputModalData: ClickUrlInputModalData | undefined = undefined;
  validatingMessageContent: boolean = false;
  invalidKeywordsMap: {[key: number]: string[]} = {};

  constructor (
    public actionType: string,
    private defaultCampaign: any,
    public campaignBasic: MessageCampaignBasic,
    public order: Order,
    public l1Object: L1Object | undefined,
    public addonFeatureManager: AddonFeatureManager,
    public onPriceModelChangeCallback: (type: MessageCampaignPlanType, campaignBasic: MessageCampaignBasic) => void,
    private otherCampaignOfL1Object?: MessageCampaignBasic[]
  ) {
    this.event = new FireableUpdateEventListener<MessageCampaignBasicFormModel>();
    this.currentPriceModel = this.campaignBasic.priceModel;
    this.canEditOptimize = this.canEditOptimize.bind(this);
    this.validator = new DefaultMessageCampaignBasicFormValidator(
      this,
      actionType,
      this.order,
      this.defaultCampaign,
      this.campaignBasic,
      this.getBidFloor.bind(this),
      this.addonFeatureManager
    );

    this.optimizeAddonEnable = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE);
  }

  abstract getBidPriceFloorData (): any;

  get campaignAdType (): AdType {
    return AdType.MESSAGE;
  }
  get showOptimizeSection (): boolean {
    return true;
  }
  get showFrequencyCap (): boolean {
    return true;
  }

  public needVideoAdViewObjective (_1) {
    return false;
  }

  setFormikValue (value: any) {
    this.formikValue = value;
  }

  getRtbOptionsMap () {
    const channel: L1ObjectChannel = _.get(this.l1Object, 'channel', L1ObjectChannel.MESSAGE);
    const channelOptionsMap = {
      [L1ObjectChannel.MESSAGE]: {
        [L1ObjectObjective.AWARENESS]: {
          [MessageCampaignPlanType.FIXED_VIEWABLE_IMPRESSION]: [
            L2ObjectOptimizationGoal.FIXED_VIEWABLE_IMPRESSION
          ]
        }
      }
    };
    return channelOptionsMap[channel];
  }

  getPriceModels () {
    const objective = this.l1Object!.objective;
    const optionsMap = this.getRtbOptionsMap();
    return Object.keys(optionsMap[objective]);
  }

  getOptimizes (priceModel: MessageCampaignPlanType) {
    const objective = this.l1Object!.objective;
    const optionsMap = this.getRtbOptionsMap();
    const optimizes = optionsMap[objective][priceModel];
    return optimizes ? optimizes : [];
  }

  getCurrentPriceModel (): MessageCampaignPlanType {
    return this.currentPriceModel;
  }

  setCurrentPriceModel (planType: MessageCampaignPlanType) {
    this.currentPriceModel = planType;
  }

  abstract showVideoProgressRadio: boolean;

  getAddonByPlanTypeOrOptimize (planType) {
    switch (planType) {
      case MessageCampaignPlanType.FIXED_VIEWABLE_IMPRESSION:
      case L2ObjectOptimizationGoal.IMPRESSIONS:
        return ADDONFEATURE.CAMPAIGN.FIXED_CPM;
      default:
        return;
    }
  }

  computeRemainCharacters (message: string | undefined, clickUrl: string | undefined) {
    if (!message) {
      return MESSAGE_MAX_LENGTH;
    }
    let validateMessage = message;
    if (message.indexOf(CLICK_URL_MACRO) !== -1 && clickUrl) {
      validateMessage = _.replace(validateMessage, new RegExp(CLICK_URL_MACRO, 'g'), getMockClickURL());
    }
    console.log('validateMessage', validateMessage);
    let length = validateMessage.length;
    for (let i = 0; i < validateMessage.length; i++) {
      let c = validateMessage.charCodeAt(i);
      if (c >= 0x2E80 && c <= 0xFFEF) {
        return MESSAGE_MAX_LENGTH - (length * 2);
      }
    }
    return MESSAGE_MAX_LENGTH - length;
  }

  get availablePriceModel () {
    const availablePriceModel: any[] = [];
    this.getPriceModels().forEach(priceModel => {
      const relatedAddon = this.getAddonByPlanTypeOrOptimize(priceModel);
      const addonEnable = relatedAddon ? this.addonFeatureManager.isFeatureEnable(relatedAddon) : true;
      if (addonEnable) {
        availablePriceModel.push(priceModel);
      }
    });
    return availablePriceModel;
  }

  get defaultPriceModel (): MessageCampaignPlanType {
    return this.availablePriceModel[0];
  }

  get priceModelOptions (): SelectOptions[] {
    const options = SelectOptionsUtils.createSelectOptionsFromEnum(
      MessageCampaignPlanType, 'campaign.labels.'
    );
    return SelectOptionsUtils.filter(options, this.availablePriceModel);
  }

  get optimizeOptions (): SelectOptions[] {
    const validOptimizes = this.getOptimizes(this.currentPriceModel);
    return validOptimizes.map(goal => ({
      label: i18n.t<string>(`optimizationGoal.${goal.toLowerCase()}`),
      value: goal
    }));
  }

  getRemainBudget (campaignBudget: number): number {
    const budgetBalance = _.get(this.order, 'budgetBalance', 0);
    const totalBudget = this.defaultCampaign.basic.isDraft || this.defaultCampaign.basic.id === undefined ?
        budgetBalance :
        budgetBalance + this.defaultCampaign.basic.budget;
    return _.round(Number(totalBudget) - campaignBudget, 2);
  }

  get budgetMinimum (): number {
    const budgetMinimum = _.get(
      this.order,
      'campaignConstraint.budgetMinimum',
      100
    );
    const spents = this.campaignBasic.spents ? this.campaignBasic.spents : 0;
    return this.campaignBasic.state === CampaignState.DEACTIVATE ? getPriceValue(this.order.currency, spents) : Number(budgetMinimum);
  }

  canEditOptimize (priceModel: MessageCampaignPlanType): boolean {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE);
  }

  get canEditEnableMonitor (): boolean {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.MONITOR.MONITOR_CAMPAIGN_BEHIND_PROGRESS);
  }

  get orderName (): string {
    return `${_.get(this.order, 'projectName')} (${_.get(
      this.order,
      'orderNumber'
    )})`;
  }

  get agencyProfit (): string | undefined {
    const agencyProfit = _.get(this.order, 'orderMargin', 0);
    return _.round(Number(agencyProfit) * 100, 2) + ' %';
  }

  get minDate (): string {
    const orderStartDate = moment(_.get(this.order, 'startDate'));
    const earliestValidHour = moment().add(1, 'day').startOf('day').hour(MIN_HOUR);
    if (this.campaignBasic && this.campaignBasic.startDate) {
      return moment(this.campaignBasic.startDate).isBefore() ? moment(this.campaignBasic.startDate).format('YYYY-MM-DD_HH:mm:ss') : earliestValidHour.format('YYYY-MM-DD_HH:mm:ss');
    }
    return moment().isAfter(orderStartDate) ? earliestValidHour.format('YYYY-MM-DD_HH:mm:ss') : orderStartDate.hour(MIN_HOUR).format('YYYY-MM-DD_HH:mm:ss');
  }

  get maxDate (): string {
    return moment(_.get(this.order, 'endDate')).endOf('day').format('YYYY-MM-DD_HH:mm:ss');
  }

  getDefaultOptimizeType (priceModel: MessageCampaignPlanType) {
    const validOptimizes = this.getOptimizes(priceModel);
    let l1ObjectDefaultOptimizationGoal = validOptimizes[0];
    if (
      this.l1Object &&
      this.l1Object.autoOptimise &&
      this.otherCampaignOfL1Object &&
      this.otherCampaignOfL1Object.length > 0
    ) {
      l1ObjectDefaultOptimizationGoal = this.otherCampaignOfL1Object[0].optimize;
    }
    return l1ObjectDefaultOptimizationGoal;
  }

  getCampaignTotalDay (startDate: string, endDate: string): number {
    const format = 'YYYY-MM-DD';
    return moment(endDate, format)
      .add(1, 'days')
      .diff(moment(startDate, format), 'days');
  }

  getBidFloor = (): number => {
    const bidFloorData = this.getBidPriceFloorData();
    return _.get(bidFloorData, 'cpm', 0);
  }

  get currency (): string {
    const currency = _.get(this.order, 'currency', Currency.NTD);
    return currency;
  }

  fetchInvalidKeywords = async (message: string) => {
    this.updateState(true, {});
    try {
      const keywordsMap = await this.messageCampaignManager.fetchInvalidKeywords(message);
      this.updateState(false, keywordsMap);
    } catch (e) {
      this.updateState(false, {});
    }
  }

  getInvalidMessageHint = () => {
    const typeKeys = Object.keys(this.invalidKeywordsMap);
    if (typeKeys.length === 0) {
      return undefined;
    }
    // Only show error if contains type 3 or contains both type 1 and type 2
    const containsType3 = typeKeys.includes('3');
    const containsType1And2 = typeKeys.includes('1') && typeKeys.includes('2');
    if (!containsType3 && !containsType1And2) {
      return undefined;
    }
    const ignoreKeywords = ['http://', 'https://', 'www.', '.com', '.net'];
    const keywords: string[] = typeKeys.reduce((acc, type) => {
      return acc.concat(this.invalidKeywordsMap[type]);
    }, [])
    // if contains keyword that is not in ignoreKeywords, show the error
    .filter((keyword: string) => ignoreKeywords.every((ignoreKeyword) => !keyword.includes(ignoreKeyword)));
    const joinSeparator = i18n.t<string>('common.joinSeparator');
    return i18n.t<string>('messageCampaignInfo.descriptions.invalidContent', {
      texts: keywords.join(joinSeparator)
    });
  }

  abstract get canEditBudgetPlan (): boolean;
  abstract get canEditPriceModel (): boolean;

  validate (campaignBasic: MessageCampaignBasic, order: Order, localeMeta?: LocaleMeta) {
    const otherCampaignCost = this.otherCampaignOfL1Object ? this.otherCampaignOfL1Object.reduce((acc, campaign) => {
      return acc + campaign.budget;
    }, 0) : 0;
    return this.validator.validate(this.l1Object, campaignBasic, order, otherCampaignCost, localeMeta);
  }

  onUnmount (handler?: number) {
    handler && this.event.remove(handler);
  }

  setClickUrlInputModalData = (data: ClickUrlInputModalData | undefined) => {
    this.clickUrlInputModalData = data;
    this.event.fireEvent(this);
  }

  updateState (validatingMessageContent: boolean, invalidKeywordsMap: {[key: number]: string[]}) {
    this.validatingMessageContent = validatingMessageContent;
    this.invalidKeywordsMap = invalidKeywordsMap;
    this.event.fireEvent(this);
  }

  get state (): MessageCampaignBasicFormState {
    return {
      clickUrlInputModalData: this.clickUrlInputModalData,
      validatingMessageContent: this.validatingMessageContent,
      invalidKeywordsMap: this.invalidKeywordsMap
    };
  }
}
