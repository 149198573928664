import React from 'react';
import { Form, Row } from 'react-bootstrap';
import LimitationSetting from './LimitationSetting/LimitationSetting';
import styles from './editLimitation.module.scss';
import { ChangeOperateAlertDialog } from 'components/CampaignLimitation/ChangeOperateAlertDialog/ChangeOperateAlertDialog';
import i18n from 'i18n';
import _ from 'lodash';
import Tags from 'components/common/Tags/Tags';
import { DefaultKeywordComponentModel } from 'components/CampaignLimitation/KeywordComponent/KeywordComponentModel';
import { AudienceInfo } from 'components/AudienceInfo/AudienceInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { LIMITATION_TYPE, unicornkeywordName } from './LimitationSetting/LimitationSettingData';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { EditLimitationModel } from './EditLimitationModel';
import { Order } from 'core/order/Order';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

export class EditLimitation extends React.Component<{
  model: EditLimitationModel,
  limitationType?: LIMITATION_TYPE,
  renderGeneralFields?: any,
  order?: Order
}, any> {
  keywordModel: any;
  handler?: number;
  constructor (props) {
    super(props);
    this.state = {
      modalShown: false,
      type: '',
      newOperate: '',
      orgOperate: '',
      limitationValue: {
        INST: true
      }
    };
    this.keywordModel = new DefaultKeywordComponentModel('name', '', () => {
      // This is intentional
    });
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      this.handler && prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  hideModal = () => {
    this.setState({
      modalShown: false
    });
  }

  changeOperate = (
    orgOperate = this.state.orgOperate,
    type = this.state.type,
    newOperate = this.state.newOperate
  ) => {
    let limitationValue = this.props.model.limitationValue;
    if (!limitationValue[newOperate]) {
      limitationValue[newOperate] = [];
    }
    let limitationdelete = limitationValue[orgOperate].findIndex(function (
      element
    ) {
      return element.type === type;
    });
    let IsInNewLimitationOperateGroup = limitationValue[newOperate].findIndex(
      function (element) {
        return element.type === type;
      }
    );
    if (IsInNewLimitationOperateGroup > -1) {
      limitationValue[newOperate].splice(IsInNewLimitationOperateGroup, 1);
    }
    limitationValue[newOperate].push(
      limitationValue[orgOperate][limitationdelete]
    );
    limitationValue[orgOperate].splice(limitationdelete, 1);
    this.hideModal();
    this.props.model.onLimitationChange();
  }

  handleChangeOpUnicornkeyword = (orgOperate, type, newOperate) => {
    if (!unicornkeywordName.includes(type)) {
      return;
    }
    let limitationValue = this.props.model.limitationValue;
    const lastUnicornkeywordTypes = _.without(unicornkeywordName, type);
    lastUnicornkeywordTypes.forEach(lastUnicornkeywordType => {
      const unicornKeyword = _.findIndex(limitationValue[orgOperate], [
        'type',
        lastUnicornkeywordType
      ]);
      const newOperateUnicornKeyword = _.findIndex(
        limitationValue[newOperate],
        ['type', lastUnicornkeywordType]
      );
      if (unicornKeyword === -1 && newOperateUnicornKeyword !== -1) {
        limitationValue[newOperate].splice(newOperateUnicornKeyword, 1);
      } else if (unicornKeyword !== -1) {
        this.changeOperate(orgOperate, lastUnicornkeywordType, newOperate);
      }
    });
  }

  handleChangeSetting = (inventory, operate) => {
    this.props.model.showInventory(inventory, operate);
  }

  handleUnicornkeywordLanguage = (operate, type) => {
    if (!unicornkeywordName.includes(type)) {
      return;
    }
    const { limitationValue } = this.props.model;
    const keywordAllNoValue = unicornkeywordName
      .filter(s => s !== 'unicornlanguage')
      .every(keywordName => {
        const keywordValue = _.find(limitationValue[operate], [
          'type',
          keywordName
        ]);
        return _.isNil(keywordValue) || keywordValue.length === 0;
      });

    if (keywordAllNoValue) {
      const lastUnicornkeywordTypes = _.without(unicornkeywordName, type);

      lastUnicornkeywordTypes.forEach(unicornkeywordType => {
        let unicornKeywordDelete = _.findIndex(limitationValue[operate], [
          'type',
          unicornkeywordType
        ]);
        if (unicornKeywordDelete !== -1) {
          limitationValue[operate].splice(unicornKeywordDelete, 1);
        }
      });
    }
  }

  handleDeleteLimitation = (operate, type) => {
    let limitationValue = this.props.model.limitationValue;
    let limitationdelete = limitationValue[operate].findIndex(function (
      element
    ) {
      return element.type === type;
    });
    if (limitationdelete !== -1) {
      limitationValue[operate].splice(limitationdelete, 1);
    }
    this.handleUnicornkeywordLanguage(operate, type);
    this.props.model.onLimitationChange();
  }

  handleChangeOperate = (orgOperate, type, newOperate) => {
    let IsInNewLimitationOperateGroup = this.props.model.limitationValue[
      newOperate
    ] ? this.props.model.limitationValue[
      newOperate
    ].findIndex(function (element) {
      return element.type === type;
    }) : -1;
    if (IsInNewLimitationOperateGroup > -1) {
      this.setState({
        modalShown: true,
        orgOperate: orgOperate,
        type: type,
        newOperate: newOperate
      });
    } else {
      this.handleChangeOpUnicornkeyword(orgOperate, type, newOperate);
      this.changeOperate(orgOperate, type, newOperate);
    }
  }

  changeUnicornkeywordOperate = (
    orgOperate = this.state.orgOperate,
    newOperate = this.state.newOperate
  ) => {
    const type = 'unicornlanguage';
    this.handleChangeOpUnicornkeyword(orgOperate, type, newOperate);
    this.changeOperate(orgOperate, type, newOperate);
  }

  handleChange = (operate, type, value) => {
    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== undefined)) {
      let limitationValue = this.props.model.limitationValue;
      if (!limitationValue[operate]) {
        limitationValue[operate] = [];
      }
      let indexOFLimitationChange = limitationValue[operate].findIndex(
        function (element) {
          return element.type === type;
        }
      );
      if (indexOFLimitationChange > -1) {
        limitationValue[operate][indexOFLimitationChange].value = value;
      } else {
        limitationValue[operate].push({ value: value, type: type });
      }
      this.props.model.onLimitationChange();
    } else {
      this.handleDeleteLimitation(operate, type);
    }
  }

  handleChangeDealId = (values) => {
    const lastValue = _.last(values);
    if (typeof lastValue === 'string') {
      values[values.length - 1] = { label: lastValue, value: lastValue };
    }

    values = values.map(value => ({ label: _.trim(value.label), value: _.trim(value.value) }));
    let limitationValue = this.props.model.limitationValue;
    let indexOFLimitationChange = limitationValue['other'].findIndex(
      (element) => element.type === 'dealId');
    if (indexOFLimitationChange > -1) {
      limitationValue['other'][indexOFLimitationChange].value = values;
    } else {
      limitationValue['other'].push({ value: values, type: 'dealId' });
    }
    this.forceUpdate();
  }

  renderLimitationPage = () => {
    const { show, inventory, fetchingEstimateData } = this.props.model.state;
    const { limitationValue, addonFeature, limitationSetting, taOptionsCache } = this.props.model;
    const { type, newOperate } = this.state;
    const handleChangeOperateForAlert = unicornkeywordName.includes(type)
      ? this.changeUnicornkeywordOperate
      : this.changeOperate;

    const defaultDealId: any = { value: [], type: 'dealId' };
    let dealId = defaultDealId;
    if (limitationValue['other']) {
      const dealIdValue = limitationValue['other'].find(function (element) {
        return element.type === 'dealId';
      });
      dealId = dealIdValue ? dealIdValue : defaultDealId;
    }
    const renderLimitationSetting = (operationSet) => {
      return (
        <LimitationSetting
          taOptionsCache={taOptionsCache}
          limitationSetting={limitationSetting}
          permission={addonFeature}
          showInventory={show === operationSet}
          value={limitationValue[operationSet]}
          onChange={this.handleChange.bind(this, operationSet)}
          onChangeInventory={this.handleChangeSetting}
          operate={operationSet}
          inventorySelected={inventory}
          onDeleteLimitation={this.handleDeleteLimitation}
          onChangeOperate={this.handleChangeOperate}
          errors={_.get(this.state.errors, operationSet)}
        />
      );
    };
    const estimateData = this.props.model.estimateData;
    const limitationType = this.props.limitationType ? this.props.limitationType : LIMITATION_TYPE.CAMPAIGN;
    const channel = _.get(this.props.model.channelData, 'channel');
    const needManualGetAudienceEstimatedData = limitationType === LIMITATION_TYPE.CAMPAIGN && (channel === L1ObjectChannel.RTB ||
      channel === L1ObjectChannel.RETAIL_MEDIA || channel === L1ObjectChannel.MESSAGE);
    const audienceEstimatedError = _.get(this.state.errors, 'audienceEstimatedError');
    const audienceDefinitionClass = cx(styles.audienceDefinition, {
      error: !!audienceEstimatedError
    });
    let errorTip;
    if (audienceEstimatedError) {
      errorTip = <span className={styles.errorTip}>{audienceEstimatedError}</span>;
    }
    return (
      <div>
        {limitationType === LIMITATION_TYPE.CAMPAIGN &&
          <>
            <fieldset className={styles.editLimitaionContainer}>
              <legend>
                <span>{i18n.t<string>('limitation.labels.audienceDefinition')}</span>
              </legend>
            </fieldset>
            <div className={styles.borderTop}/>
            <div className={audienceDefinitionClass}>
              <AudienceInfo
                loading={fetchingEstimateData}
                lowerBound={estimateData.lowerBound}
                upperBound={estimateData.upperBound}
                threshold={_.get(this.props.model.channelData, 'audienceLowestThreshold', 0)}
                estimateReady={estimateData.estimateReady}
                warning={estimateData.warning}
                estimatedHint={estimateData.estimatedHint}
                refreshData={needManualGetAudienceEstimatedData ? this.props.model.getRtbAudienceEstimatedData : undefined}
              />
              <div className={styles.importAudience} onClick={this.props.model.showTAManagement}>
                <FontAwesomeIcon icon={faFileImport}/>
                <span>{i18n.t<string>('editLimitation.buttons.importTargeting')}</span>
              </div>
            </div>
            <div className={styles.shadowCoverBottom}/>
            {errorTip}
          </>
        }
        {this.props.model.operationSet.need &&
          <fieldset className={styles.editLimitaionContainer}>
            <legend>
              <span>{i18n.t<string>('limitation.title.you.need')}</span>
            </legend>
            {this.props.renderGeneralFields && this.props.model.showGeneralFields &&
              <div className={styles.limitationSettingContainer}>
                <div className={styles.limitationSetting}>
                  <div className={styles.limitationSet}>
                    <div className={styles.generalTitle}>
                      <span>{i18n.t<string>('limitation.labels.general')}</span>
                    </div>
                    {this.props.renderGeneralFields(this.props.model.limitationValue, this.props.order, this.handleChange)}
                  </div>
                </div>
              </div>
            }
            {this.props.model.operationSet.need.map((operationSet) => (
              <div
                key={operationSet}
                className={styles.limitationSettingContainer}
              >
                {renderLimitationSetting(operationSet)}
              </div>
            ))}
          </fieldset>
        }
        {this.props.model.operationSet.notNeed &&
          <fieldset className={styles.editLimitaionContainer}>
            <legend>
              <span>{i18n.t<string>('limitation.title.you.dont.need')}</span>
            </legend>
            {this.props.model.operationSet.notNeed.map((operationSet) => (
              <div
                key={operationSet}
                className={styles.limitationSettingContainer}
              >
                {renderLimitationSetting(operationSet)}
              </div>
            ))}
          </fieldset>
        }
        {this.props.model.operationSet.other && (addonFeature.includes('campaign_enablePMPDeal') || addonFeature.includes('creative_enablePMPDeal')) &&
          <fieldset className={styles.editLimitaionContainer}>
            <legend>
              <span>{i18n.t<string>('limitation.title.other.need')}</span>
            </legend>
            <div className={styles.limitationSettingContainer}>
              <div className={styles.limitationSetting}>
                <div className={styles.limitationSet}>
                  <Form.Group as={Row} controlId={'dealId'}>
                    <Form.Label>{i18n.t<string>('limitation.labels.dealId')}</Form.Label>
                    <div style={{ flex: '1 1' }}>
                      <Tags
                        value={dealId.value}
                        onChange={this.handleChangeDealId}
                        operate={'dealId'}
                        showPlus={false}
                        placeholder={(dealId.value && dealId.value.length === 0) ? i18n.t<string>('limitation.addDealId') : ''}
                        className={styles.tags}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </fieldset>
        }
        {this.state.modalShown && (
          <ChangeOperateAlertDialog
            changeOperate={handleChangeOperateForAlert}
            dismiss={this.hideModal}
            type={type}
            newOperate={newOperate}
          />
        )}
      </div>
    );
  }

  render () {
    return (
      <div>
        {this.props.model.state.loading && <LoadingIndicator/>}
        {this.renderLimitationPage()}
      </div>
    );
  }
}
