import React, { useState } from 'react';
import styles from './rtbCampaignOptimize.module.scss';
import i18n from 'i18next';
import { FormikField } from 'components/common/form/field/FormikField';
import { Form } from 'react-bootstrap';
import { formatPriceAny } from 'helper/CurrencyHelper';
import { Dayparts } from 'components/Dayparts/Dayparts';
import _ from 'lodash';
import cx from 'classnames/bind';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { Trans } from 'react-i18next';
import Toggle from 'components/common/Toggle/Toggle';
import { LabelField } from 'components/common/form/field/LabelField';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
const classNames = cx.bind(styles);

const getOptimizationDes = (currency, bidStrategy, optimize, bidPrice) => {
  const optimization = i18n.t<string>(`optimizationGoal.${optimize.toLowerCase()}`).toLowerCase();
  return i18n.t<string>(`l2ObjectOptimize.labels.${bidStrategy.toLowerCase()}`, {
    optimization,
    bidControl: `${currency} ${formatPriceAny(isNaN(+bidPrice) ? 0 : +bidPrice)}`
  });
};

const renderDayParts = (props) => <Dayparts {...props} />;

const RtbCampaignOptimize = ({
  campaignAdType,
  formType,
  currentL2Object,
  optimizeOptions,
  canEditOptimize,
  currency,
  onOptimizeChanged,
  dayPartEnable,
  onDaypartSwitchChanged,
  creativeDeliverTypeOptions,
  bidFloor,
  showFrequencyControl,
  optimizeAddonEnable,
  canEditBidStrategy
}) => {

  const [useFrequency, setUseFrequency] = useState(!_.isEmpty(currentL2Object.frequency));

  const renderBidControl = (formikProps) => {
    const value = formikProps.values[formikProps.name];
    const onBidStrategyChange = (e) => {
      formikProps.setValues(prev => ({
        ...prev,
        [formikProps.name]: e.target.value,
        bidPrice: e.target.value === BidStrategy.LOWEST_COST_WITH_BID_CAP ? currentL2Object.orderPrice : ''
      }));
    };

    const priceModel = currentL2Object.priceModel;
    const optimize = currentL2Object.optimize;
    const rtbCampaignManager = new DefaultRtbCampaignManager();
    const optimizeSameAsPriceModel = rtbCampaignManager.checkOptimizeSameAsPriceModel(priceModel, optimize);
    const bidCapEqualOrderPrice = !optimizeAddonEnable && optimizeSameAsPriceModel;
    const bidPriceHint = optimizeSameAsPriceModel ?
      <Trans i18nKey='campaign.descriptions.bidPriceRange'>
        The bid price should be higher than
        <span className='text-dark'>{{ min: currency + bidFloor }}</span>
        and cannot exceed the contract price
        <span className='text-dark'>{{ orderPrice: currentL2Object.orderPrice ? currency + currentL2Object.orderPrice : '' }}</span>
      </Trans> :
      bidFloor && bidFloor > 0 ?
        <Trans i18nKey='campaign.descriptions.priceMinimum'>
          The price have to over <span className='text-dark'>{{ min: currency + bidFloor }}</span>
        </Trans>
        : '';
    const bidPriceFormatter = (value) => `${currency} ${value}`;
    return (
      <div className={styles.bidControl}>
        <Form.Check
          type={'radio'}
          label={i18n.t<string>('l1Object.labels.bidStrategy.lowest_cost_without_cap')}
          value={BidStrategy.LOWEST_COST_WITHOUT_CAP}
          name={'bidStrategy'}
          id={'autoBid'}
          onChange={onBidStrategyChange}
          checked={value === BidStrategy.LOWEST_COST_WITHOUT_CAP}
          disabled={!canEditBidStrategy}
        />
        <Form.Check
          type={'radio'}
          label={i18n.t<string>('l1Object.labels.bidStrategy.lowest_cost_with_bid_cap')}
          value={BidStrategy.LOWEST_COST_WITH_BID_CAP}
          name={'bidStrategy'}
          id={'bidCap'}
          onChange={onBidStrategyChange}
          checked={value === BidStrategy.LOWEST_COST_WITH_BID_CAP}
          disabled={!canEditBidStrategy}
        />
        {(value === BidStrategy.LOWEST_COST_WITH_BID_CAP) && (
          bidCapEqualOrderPrice ?
            <FormikField.Label
              name='bidPrice'
              formatter={bidPriceFormatter}
            /> :
            <FormikField.InputGroup
              name='bidPrice'
              prefix={currency}
              type='number'
              min={0}
              className={styles.bidPrice}
              hint={bidPriceHint}
            />
          )
        }
      </div>
    );
  };

  const optimizeLabelFormatter = optimization => {
    return i18n.t<string>(`optimizationGoal.${optimization.toLowerCase()}`);
  };

  const renderFrequencyControlInput = (formikProps) => {
    const onMaxFrequencyChange = e => {
      formikProps.setFieldValue('frequency.maxFrequency', e.target.value < 0 ? 0 : e.target.value);
    };
    const onIntervalDaysChange = e => {
      formikProps.setFieldValue('frequency.intervalDays', e.target.value < 0 ? 0 : e.target.value);
    };
    const frequency = formikProps.values[formikProps.name];
    const errorMaxFrequency = _.get(formikProps.errors, 'frequency.maxFrequency');
    const errorintervalDays = _.get(formikProps.errors, 'frequency.intervalDays');
    const maxFrequencyClass = classNames('maxFrequency', {
      error: errorMaxFrequency
    });
    const intervalDaysClass = classNames('intervalDays', {
      error: errorintervalDays
    });
    return (
      <div className={styles.frequencyControl}>
        <div>
          <input
            className={maxFrequencyClass}
            value={frequency.maxFrequency}
            name='frequency.maxFrequency'
            onChange={onMaxFrequencyChange}
            min={1}
            max={20}
            type='number'
          />
          {i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyCap.event', {
            event: i18n.t<string>('adSetSetupFlow.mainStep.labels.impression'),
            plural: +(frequency.maxFrequency) > 1 ? 's' : ''
          })}
          <input
            className={intervalDaysClass}
            name='frequency.intervalDays'
            value={frequency.intervalDays}
            onChange={onIntervalDaysChange}
            min={1}
            max={7}
            type='number'
          />
          {i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyCap.unit', {
            unit: i18n.t<string>('common.units.day'),
            plural: +(frequency.intervalDays) > 1 ? 's' : ''
          })}
        </div>
        {errorMaxFrequency && <div className='errorTip'>{errorMaxFrequency}</div>}
        {errorintervalDays && <div className='errorTip'>{errorintervalDays}</div>}
      </div>
    );
  };
  const renderFrequencyControlLabel = frequencyControl => {
    if (!frequencyControl) {
      return '';
    }
    return i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyControl', {
      event: i18n.t<string>('adSetSetupFlow.mainStep.labels.impression'),
      eventPlural: +(frequencyControl.maxFrequency) > 1 ? 's' : '',
      interval_days: frequencyControl.intervalDays,
      max_frequency: frequencyControl.maxFrequency,
      unit: i18n.t<string>('common.units.day'),
      unitPlural: +(frequencyControl.intervalDays) > 1 ? 's' : ''
    });
  };

  const renderUseFrequency = formikProps => {
    const onUseFrequencyChanged = (e) => {
      const use = e.target.checked;
      setUseFrequency(use);
      if (use) {
        formikProps.setFieldValue('frequency', {
          intervalDays: 1,
          maxFrequency: 5
        });
      } else {
        formikProps.setFieldValue('frequency', undefined);
      }
    };

    return (
      <Toggle
        className={styles.useFrequency}
        checked={useFrequency}
        onChange={onUseFrequencyChanged}
      />
    );
  };

  return (
    <fieldset>
      <legend>
      <span>{i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle')}</span>
      </legend>
      <div className={styles.component}>
        {canEditOptimize ?
          <FormikField.Select
            options={optimizeOptions}
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal')}
            name='optimize'
            simpleValue
            onChange={onOptimizeChanged}
          /> :
          <FormikField.Label
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal')}
            name='optimize'
            formatter={optimizeLabelFormatter}
          />
        }
        <FormikField.Custom
          label={i18n.t<string>('adSetSetupFlow.mainStep.field.bidControl')}
          name='bidStrategy'
          render={renderBidControl}
          hint={
            <div style={{ maxWidth: 500 }}>
              {getOptimizationDes(currency, _.get(currentL2Object, 'bidStrategy'), _.get(currentL2Object, 'optimize', ''), currentL2Object.bidPrice)}
            </div>
          }
        />
        <LabelField
          name='billingEvent'
          label={i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent')}
          className={styles.billingEvent}
          value={
            !currentL2Object.priceModel || !currentL2Object.optimize ?
            i18n.t<string>('rtbCampaign.billingEvent.unspecified') :
            currentL2Object.priceModel === RtbCampaignPlanType.RS ?
              i18n.t<string>(`rtbCampaign.billingEvent.${currentL2Object.priceModel}.${currentL2Object.optimize.toLowerCase()}`) :
              i18n.t<string>(`rtbCampaign.billingEvent.${currentL2Object.priceModel}`)
          }
        />
        <FormikField.ToggleButton
          name='creativeDeliverType'
          label={i18n.t<string>('campaignInfo.labels.creativeDeliverType')}
          options={creativeDeliverTypeOptions}
        />
        {
          currentL2Object.optimize === L2ObjectOptimizationGoal.CLICKS && (
            <FormikField.Custom
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.useFrequencyControl')}
              render={renderUseFrequency}
            />
          )
        }
        {(showFrequencyControl || useFrequency) && !_.isEmpty(currentL2Object.frequency) && (
          formType === 'create' ?
            <FormikField.Custom
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.frequencyControl')}
              name='frequency'
              render={renderFrequencyControlInput}
            /> :
            <FormikField.Label
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.frequencyControl')}
              name='frequency'
              formatter={renderFrequencyControlLabel}
            />
          )
        }
        {campaignAdType !== AdType.PILOT_TV &&
          <div className={styles.section}>
            <FormikField.Switch
              label={i18n.t<string>('campaignInfo.labels.daypart')}
              name='dayPart.enabled'
              onChange={onDaypartSwitchChanged}
            />
            {!!dayPartEnable &&
              <FormikField.Custom
                label=''
                name='dayPart'
                render={renderDayParts}
              />
            }
          </div>
        }
      </div>
    </fieldset>
  );
};

export default React.memo(RtbCampaignOptimize);
