import _ from 'lodash';

import { DefaultAsyncSourceSpanModel } from 'components/AsyncSourceSpan/AsyncSourceSpanModel';
import { DomainCustomInputComponentModel, SpaceCustomInputComponentModel } from 'components/CampaignLimitation';
import { KeywordComponent } from 'components/CampaignLimitation/KeywordComponent/KeywordComponent';
import { SearchKeywordsComponent } from 'components/CampaignLimitation/SearchKeywordsComponent/SearchKeywordsComponent';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { ItemType, defaultItemSetting } from 'components/CampaignLimitation/SelectItemComponent';
import { SelectOptions } from 'components/common/commonType';
import { LocaleMeta } from 'core';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { OrderType } from 'core/order/Order';
import { TiktokPlacementMapping } from 'core/tiktokAdGroup/TiktokAdGroup';
import { OPERATE } from 'enum/Operate';
import i18n from 'i18next';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import { renderKeywordSettingSearchResult } from './LimitationSettingHintRenderFunctions';
import { renderProductGroupTips } from './ProductGroupTips';
import { fbTAInventories } from './fbTAInventory';
import { rtbInventories } from './rtbLimitationInventory';
import { tiktokTAInventories } from './tiktokTAInventory';
import { AdType } from 'core/rtbCampaign/RtbCampaign';

const defaultAdRequestSourceManager = new DefaultAdRequestSourceManager();
const limitationManager = new DefaultLimitationManager();

export const DEFAULT_INVENTORY = 'default';
export const operateTitles = {
  [OPERATE.INCLUDE]: {
    0: 'limitation.labels.incNoValue',
    1: 'limitation.labels.incHaveValue'
  },
  [OPERATE.EXCLUDE]: {
    0: 'limitation.labels.excNoValue',
    1: 'limitation.labels.excHaveValue'
  },
  [OPERATE.PREFERRED]: {
    0: 'limitation.labels.preferNoValue',
    1: 'limitation.labels.preferHaveValue'
  },
  [OPERATE.NONPREFERRED]: {
    0: 'limitation.labels.nonpreferNoValue',
    1: 'limitation.labels.nonpreferHaveValue'
  }
};
export const unicornkeywordName = [
  'unicornkeyword1',
  'unicornkeyword2',
  'unicornlanguage'
];
export enum LIMITATION_TYPE {
  CAMPAIGN = 'campaign',
  CREATIVE = 'creative'
}

const getArticleCategoriesInfoFunc = (categoryLimitations, adRequestSourceManager) => {
  if (categoryLimitations.length === 1) {
    return async () => {
      const categories = await adRequestSourceManager.getArticleCategories();
      const mainCategory = categoryLimitations[0].split('-')[0];
      const targetMainCategory = categories.find(category => category.label === mainCategory);
      if (targetMainCategory) {
        if (targetMainCategory.value === categoryLimitations[0]) {
          return targetMainCategory.extra;
        } else {
          const options = _.defaultTo(targetMainCategory.options, []);
          const option = options.find(option => option.value === categoryLimitations[0]);
          return _.get(option, 'extra', 0);
        }
      }
      return 0;
    };
  }
  return _.partial(limitationManager.categoriesUnionCount.bind(limitationManager), categoryLimitations);
};

export const defaultInventorySetting = (
  requiredOperateOfTaTypes: {[type: string]: string[]},
  advertiserId: number,
  limitationType: LIMITATION_TYPE,
  goSegments?: SelectOptions[],
  localeMeta?: LocaleMeta,
  adType?: AdType,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => [
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent
  },
  {
    ...rtbInventories.geography,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.geography.name],
    addonFeature: `${limitationType}_country`,
    cb: () => adRequestSourceManager.getCountries(),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchCountryCity',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation, needShow) => {
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t<string>('limitation.labels.geograthyCanNotNull'))
      } : undefined;
    }
  },
  {
    ...rtbInventories.carrier,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.carrier.name],
    addonFeature: `${limitationType}_carrier`,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.carrier.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchCarrier',
    cb: adRequestSourceManager.getCarriers.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    ...rtbInventories.os,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.os.name],
    addonFeature: `${limitationType}_device`,
    cb: adRequestSourceManager.getOS.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchOs',
    component: SelectComponent
  },
  {
    ...rtbInventories.device,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.device.name],
    addonFeature: `${limitationType}_device`,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.device.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchDevice',
    cb: () => adRequestSourceManager.getDevice(),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    // Legacy PMax2.0 setting, which is removed within RtbCampaignSetupFlowPageModel from include/exclude/preferred/non-preferred inventory
    ...rtbInventories.age,
    addonFeature: `${limitationType}_age`,
    cb: adRequestSourceManager.getAges.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchAges',
    component: SelectComponent
  },
  {
    // Legacy PMax2.0 setting, which is removed within RtbCampaignSetupFlowPageModel from include/exclude/preferred/non-preferred inventory
    ...rtbInventories.gender,
    addonFeature: `${limitationType}_gender`,
    cb: adRequestSourceManager.getGenders.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.gender.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchGenders',
    component: SelectComponent
  },
  {
    ...rtbInventories.agencySegment,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.agencySegment.name],
    ignoreValidateOption: true,
    addonFeature: 'agencySegment',
    title: i18n.t('limitation.labels.agencySegment', { name: _.get(localeMeta, 'property.addonProps.agencySegmentLimitationName', '') }),
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchAgencySegment',
    cb: () => adRequestSourceManager.getAgencySegments(_.get(localeMeta, 'agencyId')),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    ...rtbInventories.segment,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.segment.name],
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_target_audience`,
    title: goSegments ? 'limitation.labels.gojekSegment' : 'limitation.labels.segment',
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.autoByGroupSetting,
      categorySelectable: false
    },
    searchPlaceholder: goSegments ? 'limitation.placeholders.searchGojekSegment' : 'limitation.placeholders.searchSegment',
    cb: goSegments ?
      () => goSegments :
      adRequestSourceManager.getSegments.bind(adRequestSourceManager, advertiserId),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    ...rtbInventories.tenmaxAudience,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.tenmaxAudience.name],
    addonFeature: `${limitationType}_tenmax_audience`,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.withCategory,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchTenmaxSegments',
    cb: adRequestSourceManager.getTenmaxSegments.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    ...rtbInventories.contentcat,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.contentcat.name],
    addonFeature: `${limitationType}_content_category`,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.withCategory,
      i18nPrefix: rtbInventories.contentcat.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchContentCat',
    cb: adRequestSourceManager.getArticleCategories.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation) => {
      const value = _.map(_.get(limitation['contentcat'], 'value', []), 'value');
      const dataSource = getArticleCategoriesInfoFunc(value, adRequestSourceManager);
      const dataDecorator = (data) => i18n.t<string>('limitation.contentCategory.countHint', { count: data });
      return {
        key: value.join(','),
        model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
      };
    },
    component: SelectComponent
  },
  {
    ...rtbInventories.unicornkeywords,
    addonFeature: `${limitationType}_keyword`,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: KeywordComponent
  },
  {
    ...rtbInventories.adx,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.adx.name],
    addonFeature: `${limitationType}_adexchange`,
    cb: adRequestSourceManager.getAdx.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchAdx',
    component: SelectComponent,
    postCommentPropsGetter: (limitation, needShow) => {
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t<string>('limitation.labels.adxCanNotNull'))
      } : undefined;
    }
  },
  {
    ...rtbInventories.spaceType,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.spaceType.name],
    addonFeature: `${limitationType}_spaceType`,
    cb: adRequestSourceManager.getSpaceTypes.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.spaceType.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchSpaceType',
    component: SelectComponent
  },
  {
    ...rtbInventories.adspace,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.adspace.name],
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_asiamax_space`,
    cb: () => adType ? adRequestSourceManager.getSSPSpacesByAdType(adType) : adRequestSourceManager.getSSPSpaces(),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchSpace',
    customInputModelGetter: () => new SpaceCustomInputComponentModel(),
    component: SelectComponent,
    renderTips: (limitation: SelectOptions) => {
      return limitation.isGroup ? renderProductGroupTips(limitation) : undefined;
    }
  },
  {
    ...rtbInventories.contentlang,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.contentlang.name],
    addonFeature: `${limitationType}_content_language`,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.contentlang.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchContentLang',
    cb: adRequestSourceManager.getContentLanguages.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    ...rtbInventories.adcat,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.adcat.name],
    addonFeature: `${limitationType}_space_category`,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.adcat.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchAdCat',
    cb: adRequestSourceManager.getTenmaxCategories.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    component: SelectComponent
  },
  {
    ...rtbInventories.domain,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.domain.name],
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_domain`,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchDomains',
    cb: adRequestSourceManager.getDomains.bind(adRequestSourceManager),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    customInputModelGetter: () => new DomainCustomInputComponentModel(),
    component: SelectComponent,
    renderTips: (limitation: SelectOptions) => {
      return limitation.isGroup ? renderProductGroupTips(limitation) : undefined;
    }
  },
  {
    ...rtbInventories.adFormat,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.adFormat.name],
    ignoreAddonFeature: true,
    cb: () => ([{
      label: 'instl',
      value: 'instl'
    }]),
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: rtbInventories.adFormat.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchAdFormat',
    component: SelectComponent
  }
];

export const outdoorInventorySetting = (
  requiredOperateOfTaTypes: {[type: string]: string[]},
  limitationType: LIMITATION_TYPE,
  adType?: AdType,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => [
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent
  },
  {
    ...rtbInventories.geography,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.geography.name],
    addonFeature: `${limitationType}_country`,
    cb: adRequestSourceManager.getCountries.bind(adRequestSourceManager),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchCountryCity',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation, needShow) => {
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t<string>('limitation.labels.geograthyCanNotNull'))
      } : undefined;
    }
  },
  {
    ...rtbInventories.adx,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.adx.name],
    addonFeature: `${limitationType}_adexchange`,
    cb: adRequestSourceManager.getAdx.bind(adRequestSourceManager),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchAdx',
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    postCommentPropsGetter: (limitation, needShow) => {
      return needShow ? {
        model: new DefaultAsyncSourceSpanModel(() => i18n.t<string>('limitation.labels.adxCanNotNull'))
      } : undefined;
    }
  },
  {
    ...rtbInventories.adspace,
    requiredOperate: requiredOperateOfTaTypes[rtbInventories.adspace.name],
    ignoreValidateOption: true,
    addonFeature: `${limitationType}_asiamax_space`,
    cb: () => adType ? adRequestSourceManager.getSSPSpacesByAdType(adType) : adRequestSourceManager.getSSPSpaces(),
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchSpace',
    customInputModelGetter: () => new SpaceCustomInputComponentModel(),
    component: SelectComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
    renderTips: (limitation: SelectOptions) => {
      return limitation.isGroup ? renderProductGroupTips(limitation) : undefined;
    }
  }
];

export const messageInventorySetting = (
  segmentsOptions: SelectOptions[]
) => {
  return [
    {
      name: DEFAULT_INVENTORY,
      ignoreAddonFeature: true,
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: '',
      component: SelectComponent
    },
    ...segmentsOptions.map(segment => ({
      name: segment.label,
      title: `limitation.labels.${segment.label}`,
      ignoreAddonFeature: true,
      ignoreValidateOption: true,
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        type: ItemType.autoByGroupSetting,
        categorySelectable: false
      },
      searchPlaceholder: 'limitation.placeholders.searchGojekSegment',
      cb: () => {
        return segment.options?.map(option => {
          if (segment.label === 'custom_segment') {
            return option;
          }
          return {
            ...option,
            label: i18n.t<string>(`limitation.labels.${option.label}`)
          };
        });
      },
      component: SelectComponent,
      supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
    }))
  ];
};

// const keywordValidator = (_, value) => {
//   if (!value) {
//     return;
//   }
//   if (value.length > 30) {
//     return i18n.t<string>('editLimitation.errors.tooManyKeywords');
//   }
// };

export const keywordInventorySetting = [
  {
    name: 'unicornkeyword1',
    title: 'limitation.labels.unicornkeyword1',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    postCommentPropsGetter: (limitation) => {
      const keyword1 = _.map(_.get(limitation['unicornkeyword1'], 'value', []), 'value');
      const keyword2 = _.map(_.get(limitation['unicornkeyword2'], 'value', []), 'value');
      const keywordlanguage = _.map(_.get(limitation['unicornlanguage'], 'value', [{ label: 'zh', value: 'zh' }]), 'value')[0];
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = _.partial(limitationManager.keywordEstimate.bind(limitationManager), {
        firstQueryParameters: keyword1,
        secondQueryParameters: keyword2,
        language: keywordlanguage
      });
      const dataDecorator = (data) => renderKeywordSettingSearchResult(i18n.t<string>('limitation.labels.unicornkeyword1'), data);
      return keywordUseAndFeature ? undefined : {
        key: `${keyword1.join(',')},${keyword2.join(',')},${keywordlanguage}`,
        model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
      };
    },
    component: KeywordComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
    // validator: keywordValidator
  },
  {
    name: 'unicornkeyword2',
    title: 'limitation.labels.unicornkeyword2',
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    preCommentPropsGetter: (limitation) => {
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = () => keywordUseAndFeature ? i18n.t<string>('limitation.labels.and') : '';
      return keywordUseAndFeature ? {
        key: dataSource(),
        model: new DefaultAsyncSourceSpanModel(dataSource)
      } : undefined;
    },
    postCommentPropsGetter: (limitation) => {
      const keyword1 = _.map(_.get(limitation['unicornkeyword1'], 'value', []), 'value');
      const keyword2 = _.map(_.get(limitation['unicornkeyword2'], 'value', []), 'value');
      const keywordlanguage = _.map(_.get(limitation['unicornlanguage'], 'value', [{ label: 'zh', value: 'zh' }]), 'value')[0];
      const keywordUseAndFeature = limitation['unicornkeyword1'] && limitation['unicornkeyword2'];
      const dataSource = _.partial(limitationManager.keywordEstimate.bind(limitationManager), {
        firstQueryParameters: keyword1,
        secondQueryParameters: keyword2,
        language: keywordlanguage
      });
      const name = keywordUseAndFeature ?
        `${i18n.t<string>('limitation.labels.unicornkeyword1')} ${i18n.t<string>('limitation.labels.unicornkeyword2')}` :
        i18n.t<string>('limitation.labels.unicornkeyword2');
      const dataDecorator = (data) => renderKeywordSettingSearchResult(name, data);
      return {
        key: `${keyword1.join(',')},${keyword2.join(',')},${keywordlanguage}`,
        model: new DefaultAsyncSourceSpanModel(dataSource, dataDecorator)
      };
    },
    component: KeywordComponent,
    supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
    // validator: keywordValidator
  }
];

export const inventoryNameList = (limitationType: LIMITATION_TYPE) => {
  return defaultInventorySetting({}, 0, limitationType).map(inventory => inventory.name);
};

export const adSetInventorySetting = (
  operate: string,
  optimizationGoal: L2ObjectOptimizationGoal | undefined,
  fbCountries: SelectOptions[],
  goSegments: SelectOptions[],
  orderType: OrderType
) => _.compact([
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  },
  {
    ...fbTAInventories.geo_locations,
    requiredOperate: optimizationGoal !== L2ObjectOptimizationGoal.SALES ? ['include'] : undefined,
    ignoreAddonFeature: true,
    cb: () => fbCountries,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      hideExtra: true
    },
    searchPlaceholder: 'limitation.placeholders.searchCountryCity',
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  },
  {
    ...fbTAInventories.publisher_platforms,
    ignoreAddonFeature: true,
    cb: () => ([
      {
        label: 'Facebook',
        value: 'facebook'
      },
      {
        label: 'Instagram',
        value: 'instagram'
      },
      {
        label: 'Audience Network',
        value: 'audience_network'
      }
    ]),
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'targeting.fb.placeholders.search_publisher_platforms',
    validator: (_1, value: SelectOptions[]) => {
      if (value.length === 1 && value[0].value === 'audience_network') {
        return i18n.t<string>('targeting.fb.errors.audience_network');
      }
    },
    component: SelectComponent,
    supportOperates: ['include']
  },
  orderType === OrderType.GOJEK ? {
    ...fbTAInventories.segment,
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.autoByGroupSetting,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchGojekSegment',
    cb: () => goSegments,
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  } : undefined,
  {
    ...fbTAInventories.user_os,
    showWithLimitation: ['user_device'],
    requiredOperate: optimizationGoal === L2ObjectOptimizationGoal.SALES ? ['include'] : undefined,
    ignoreAddonFeature: true,
    disable: optimizationGoal === L2ObjectOptimizationGoal.SALES && operate === 'edit',
    cb: () => ([{ label: 'Android', value: 'Android' }, { label: 'iOS', value: 'iOS' }]),
    singleSelect: optimizationGoal === L2ObjectOptimizationGoal.SALES,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchOs',
    component: SelectComponent,
    validator: (operate, value, limitationValue) => {
      if (optimizationGoal === L2ObjectOptimizationGoal.SALES && (!value || value.length === 0)) {
        return i18n.t<string>('targeting.fb.errors.user_os');
      }
      const userDevice = limitationValue[operate].find(ta => ta.type === 'user_device');
      if (userDevice && userDevice.value.length > 0 && (!value || value.length === 0)) {
        return i18n.t<string>('targeting.fb.errors.user_device_need_os');
      }
    },
    supportOperates: ['include']
  },
  {
    ...fbTAInventories.user_device,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      i18nPrefix: fbTAInventories.user_device.i18nPrefix
    },
    searchPlaceholder: 'limitation.placeholders.searchDevice',
    cb: () => ([{ label: 'Smartphone', value: 'Smartphone' }, { label: 'Tablet', value: 'Tablet' }]),
    component: SelectComponent,
    supportOperates: ['include']
  }
]);

export const adGroupInventorySetting = (
  operate: string,
  objective: string,
  tiktokCountires: SelectOptions[],
  goSegments: SelectOptions[],
  orderType: OrderType
): any[] => _.compact([
  {
    name: DEFAULT_INVENTORY,
    ignoreAddonFeature: true,
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: '',
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  },
  {
    ...tiktokTAInventories['tiktok_location'],
    requiredOperate: ['include'],
    ignoreAddonFeature: true,
    cb: () => tiktokCountires,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      hideExtra: true
    },
    searchPlaceholder: 'limitation.placeholders.searchCountryProvince',
    component: SelectComponent,
    supportOperates: ['include']
  },
  {
    ...tiktokTAInventories['tiktok_placement'],
    requiredOperate: ['include'],
    disable: operate === 'edit',
    disableReason: 'limitation.hints.tiktokPlacementCannotModify',
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    cb: () => {
      const placements = createSelectOptions(Object.values(TiktokPlacementMapping), 'targeting.tiktok.placements.', value => value.toLowerCase());
      if (objective === L1ObjectObjective.AWARENESS) {
        return placements.filter(placement => placement.value === TiktokPlacementMapping.TIKTOK);
      } else {
        return placements;
      }
    },
    singleSelect: false,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'targeting.tiktok.placeholders.search_placements',
    component: SelectComponent,
    supportOperates: ['include'],
    validator: (_, value) => {
      if (!value || value.length === 0) {
        return;
      }
      if (objective === L1ObjectObjective.AWARENESS) {
        const values = value.map(limit => limit.value);
        if (values.length > 1 || !values.includes(TiktokPlacementMapping.TIKTOK)) {
          return i18n.t<string>('targeting.tiktok.errors.reach_placement_only_tiktok');
        }
      }
    }
  },
  {
    ...tiktokTAInventories['tiktok_os'],
    ignoreAddonFeature: true,
    cb: () => createSelectOptions(['ANDROID', 'IOS'], 'targeting.tiktok.os.', value => value.toLowerCase()),
    singleSelect: true,
    itemSetting: defaultItemSetting(),
    searchPlaceholder: 'limitation.placeholders.searchOs',
    component: SelectComponent,
    supportOperates: ['include']
  },
  orderType === OrderType.GOJEK ? {
    ...tiktokTAInventories['tiktok_audience'],
    ignoreAddonFeature: true,
    ignoreValidateOption: true,
    singleSelect: false,
    itemSetting: {
      ...defaultItemSetting(),
      type: ItemType.autoByGroupSetting,
      categorySelectable: false
    },
    searchPlaceholder: 'limitation.placeholders.searchGojekSegment',
    cb: () => goSegments,
    component: SelectComponent,
    supportOperates: ['include', 'exclude']
  } : undefined
]);

export const retailMediaInventorySetting = (
  requiredOperateOfTaTypes: {[type: string]: string[]},
  advertiserId: number,
  limitationType: LIMITATION_TYPE,
  goSegments?: SelectOptions[],
  localeMeta?: LocaleMeta,
  retailType?: string,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => {
  let defaultSetting: any[] = defaultInventorySetting(requiredOperateOfTaTypes, advertiserId, limitationType, goSegments, localeMeta).filter(setting => setting.name !== 'adx' && setting.name !== 'adspace');
  let segmentIndex: number = _.findIndex(defaultSetting, inventory => inventory.name === 'segment');
  defaultSetting = [
    ..._.slice(defaultSetting, 0, segmentIndex + 1),
    {
      name: 'product_segment',
      ignoreValidateOption: true,
      ignoreAddonFeature: true,
      title: 'limitation.labels.productSegment',
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        type: ItemType.autoByGroupSetting,
        categorySelectable: false
      },
      searchPlaceholder: 'limitation.placeholders.searchProductSegments',
      cb: retailType ?
        adRequestSourceManager.getProductSegments.bind(adRequestSourceManager, advertiserId, retailType) :
        () => [],
      supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
      component: SelectComponent
    },
    ..._.slice(defaultSetting, segmentIndex + 1),
    {
      name: 'searchKeywords',
      ignoreAddonFeature: true,
      ignoreValidateOption: true,
      title: 'limitation.labels.searchKeyword',
      singleSelect: false,
      itemSetting: defaultItemSetting(),
      searchPlaceholder: '',
      component: SearchKeywordsComponent,
      supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred'],
      extra: {
        retailType
      }
    },
    {
      name: 'adspace',
      ignoreAddonFeature: true,
      title: 'limitation.labels.placement',
      cb: async () => {
        const spaces = await adRequestSourceManager.getSSPSpaces();
        return spaces.filter(space => _.get(space, 'isGroup', false));
      },
      singleSelect: false,
      itemSetting: {
        ...defaultItemSetting(),
        categorySelectable: false
      },
      searchPlaceholder: 'limitation.placeholders.searchPlacement',
      component: SelectComponent,
      supportOperates: ['include', 'exclude', 'preferred', 'nonPreferred']
    }
  ];
  return defaultSetting;
};
