import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { CreativeOfCampaign } from 'core/creative/Creative';
import _ from 'lodash';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { DefaultCreativeListModel, CreativeListColumns, CreativeListModel } from 'components/CampaignCreativeList/CreativeListModel';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import rtbBindingListFormatters from 'components/CampaignCreativeList/defaultListFormatters';
import fbAdListFormatters from 'components/CampaignCreativeList/fbAdListFormatters';
import tiktokAdListFormatters from 'components/CampaignCreativeList/tiktokAdListFormatters';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';
import { BindingManager, DefaultBindingManager } from 'core/binding/BindingManager';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Permission } from 'core/auth/Permission';
import { addOnEnabled, hasFuncs, isPmax3Order, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { PermissionItem } from 'core';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';

export interface CreativeOverviewStateContentModel {
  readonly state: CreativeOverviewStateContentState;
  readonly channel: L1ObjectChannel;
  readonly event: UpdateEventListener<CreativeOverviewStateContentModel>;
  readonly creatives: Array<CreativeOfCampaign>;
  readonly selectedCreatives: Array<number>;
  readonly stateContext?: CreativeManagementStateContext;
  readonly campaignInfo: any;
  readonly closingReportEnabled: boolean;
  readonly showCreateCreativeBtn: boolean;
  readonly columnsToShow?: Array<string>;
  readonly l1Object?: L1Object;
  readonly bindPermissionAware: PermissionItem;
  l3ObjectStateFn: () => void;
  goCreateCreativeFn: () => void;
  handleOnSelect (creativeId: Array<any>): void;
  handleOnSelectAll (): void;
  handleOnUnSelectAll (): void;
  activeCreatives (): Promise<void>;
  deactiveCreatives (): Promise<void>;
  setStateContext (stateContext: CreativeManagementStateContext): void;
  getEditPath (creativeId): string;
  canActive (): boolean;
  canDeactive (): boolean;
  getCreativeListModel (): CreativeListModel;
}

export type CreativeOverviewStateContentProps = {
  readonly model: CreativeOverviewStateContentModel;
};

export type CreativeOverviewStateContentState = {
  readonly selectedCreatives: Array<number>;
  readonly loading: boolean;
  readonly dirty: boolean;
};

abstract class DefaultCreativeOverviewStateContentModel implements CreativeOverviewStateContentModel {
  event: FireableUpdateEventListener<CreativeOverviewStateContentModel>;
  creatives: Array<CreativeOfCampaign>;
  campaignInfo: any;
  selectedCreatives: Array<number>;
  loading: boolean;
  stateContext?: CreativeManagementStateContext;
  closingReportEnabled: boolean;
  dirty: boolean;
  orderNumber: string;
  showCreateCreativeBtn = true;
  isDraft: boolean = false;
  bindingIdMap: any = {};

  constructor (
    public l1Object: L1Object,
    campaignInfo: any,
    creatives: Array<CreativeOfCampaign>,
    closingReportEnabled: boolean,
    orderNumber: string,
    public l3ObjectStateFn,
    public goCreateCreativeFn,
    protected refreshCreative,
    protected bindingManager: BindingManager = new DefaultBindingManager()
  ) {
    this.event = new FireableUpdateEventListener<CreativeOverviewStateContentModel>();
    this.creatives = creatives;
    this.campaignInfo = campaignInfo;
    this.selectedCreatives = [];
    this.loading = false;
    this.closingReportEnabled = closingReportEnabled;
    this.dirty = false;
    this.orderNumber = orderNumber;
    this.isDraft = !!campaignInfo.draftId;
    creatives.forEach(creative => {
      this.bindingIdMap[creative.id] = creative.l3ChannelId;
    });
  }

  get state (): CreativeOverviewStateContentState {
    return {
      selectedCreatives: this.selectedCreatives,
      loading: this.loading,
      dirty: this.dirty
    };
  }

  get bindPermissionAware () {
    const channelAddonFeature = ADDONFEATURE.CHANNEL[this.channel];
    return channelAddonFeature ?
      hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(channelAddonFeature)).and(isPmax3Order(this.l1Object.adsOrderId)).and(notSelfServeAdObject(this.l1Object)) :
      hasFuncs(Permission.CAMPAIGN_WRITE).and(isPmax3Order(this.l1Object.adsOrderId)).and(notSelfServeAdObject(this.l1Object));
  }

  getEditPath = (creativeId) => {
    const postfitPath = this.campaignInfo.draftId ? `&draftId=${this.campaignInfo.draftId}` : `&campaignId=${this.campaignInfo.id}`;
    return this.l1Object ?
      `/creatives/${creativeId}/edit?orderNumber=${this.orderNumber}&l1ObjectId=${this.l1Object.l1ObjectId}${postfitPath}` :
      `/creatives/${creativeId}/edit?orderNumber=${this.orderNumber}${postfitPath}`;
  }

  setStateContext = (stateContext) => {
    this.stateContext = stateContext;
  }

  abstract canActive ();

  abstract canDeactive ();

  abstract get channel ();

  async updateStatusMethod (active: boolean) {
    let payload: any = {
      active,
      draft: this.isDraft
    };
    if (this.isDraft) {
      payload = [{
        ...payload,
        goCreativeIds: this.selectedCreatives,
        l2ChannelIds: [this.campaignInfo.draftId]
      }];
    } else {
      payload = [{
        ...payload,
        l3ChannelIds: this.selectedCreatives.map((creativeId: any) => this.bindingIdMap[creativeId])
      }];
    }
    await this.bindingManager.updateBinding(this.channel, payload);
  }

  abstract get listFormatters ();

  abstract get columnsToShow ();

  activeCreatives = async () => {
    if (this.selectedCreatives.length <= 0) {
      return;
    }

    try {
      this.updateState(true);
      await this.updateStatusMethod(true);
      this.creatives = await this.refreshCreative();
      this.selectedCreatives = [];
      toast.success(i18n.t<string>('creativeOverviewState.labels.activeSuccess'));
      this.dirty = true;
      this.updateState();
    } catch (e) {
      toast.error(i18n.t<string>('creativeOverviewState.labels.activeFailed'));
      this.updateState();
    }
  }

  deactiveCreatives = async () => {
    if (this.selectedCreatives.length <= 0) {
      return;
    }

    try {
      this.updateState(true);
      await this.updateStatusMethod(false);
      this.creatives = await this.refreshCreative();
      this.selectedCreatives = [];
      toast.success(i18n.t<string>('creativeOverviewState.labels.deactiveSuccess'));
      this.dirty = true;
      this.updateState();
    } catch (e) {
      toast.error(i18n.t<string>('creativeOverviewState.labels.deactiveFailed'));
      this.updateState();
    }
  }

  handleOnSelect = (creativeId) => {
    if (this.selectedCreatives.indexOf(creativeId) > -1) {
      _.remove(this.selectedCreatives, id => id === creativeId);
    } else {
      this.selectedCreatives.push(creativeId);
    }
    this.updateState();
  }

  handleOnSelectAll = () => {
    if (this.selectedCreatives.length === this.creatives.length) {
      this.selectedCreatives = [];
    } else {
      this.selectedCreatives = this.creatives.map(creative => creative.id);
    }

    this.updateState();
  }

  handleOnUnSelectAll = () => {
    this.selectedCreatives = [];
    this.updateState();
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

  getCreativeListModel (): CreativeListModel {
    return new DefaultCreativeListModel(
      this.l1Object,
      this.campaignInfo,
      this.creatives,
      this.selectedCreatives,
      this.closingReportEnabled,
      this.handleOnSelect,
      this.handleOnSelectAll,
      this.getEditPath,
      this.listFormatters,
      this.columnsToShow,
      this.bindPermissionAware
    );
  }
}

export class RtbCreativeOverviewStateContentModel extends DefaultCreativeOverviewStateContentModel {

  constructor (
    l1Object: L1Object,
    campaignInfo: any,
    creatives: Array<CreativeOfCampaign>,
    closingReportEnabled: boolean,
    orderNumber: string,
    l3ObjectStateFn,
    goCreateCreativeFn,
    refreshCreative,
    bindingManager: BindingManager = new DefaultBindingManager(),
    protected rtbCampaignManager = new DefaultRtbCampaignManager()
  ) {
    super(l1Object, campaignInfo, creatives, closingReportEnabled, orderNumber, l3ObjectStateFn, goCreateCreativeFn, refreshCreative, bindingManager);
  }

  get channel () {
    return L1ObjectChannel.RTB;
  }

  canActive = () => {
    return true;
  }

  canDeactive = () => {
    return true;
  }

  get listFormatters () {
    return rtbBindingListFormatters;
  }

  get columnsToShow () {
    const basicColumns = [
      CreativeListColumns.ID,
      CreativeListColumns.PREVIEW,
      CreativeListColumns.STATUS,
      CreativeListColumns.DELIVERY,
      CreativeListColumns.RESULTS,
      CreativeListColumns.CTR,
      CreativeListColumns.IMPRES,
      CreativeListColumns.CLICKS,
      CreativeListColumns.UU,
      CreativeListColumns.TYPE,
      CreativeListColumns.APPROVALSTATUS,
      CreativeListColumns.TOOL
    ];
    if (this.rtbCampaignManager.isOutdoorAdType(this.campaignInfo.adType)) {
      return basicColumns.filter(type => type !== CreativeListColumns.APPROVALSTATUS);
    }
    return basicColumns;
  }
}

export class RetailCreativeOverviewStateContentModel extends RtbCreativeOverviewStateContentModel {

  get channel () {
    return L1ObjectChannel.RETAIL_MEDIA;
  }

  get columnsToShow () {
    return [
      CreativeListColumns.ID,
      CreativeListColumns.PREVIEW,
      CreativeListColumns.STATUS,
      CreativeListColumns.LAYOUT,
      CreativeListColumns.PRODUCTSET,
      CreativeListColumns.DELIVERY,
      CreativeListColumns.RESULTS,
      CreativeListColumns.CTR,
      CreativeListColumns.IMPRES,
      CreativeListColumns.CLICKS,
      CreativeListColumns.UU,
      CreativeListColumns.TYPE,
      CreativeListColumns.TOOL
    ];
  }
}

export class EdiMaxCreativeOverviewStateContentModel extends RtbCreativeOverviewStateContentModel {

  get channel (): L1ObjectChannel {
    return L1ObjectChannel.EDIMAX;
  }
}

export class PICCreativeOverviewStateContentModel extends RtbCreativeOverviewStateContentModel {

  get channel (): L1ObjectChannel {
    return L1ObjectChannel.PIC;
  }
}

export class FbAdOverviewStateContentModel extends DefaultCreativeOverviewStateContentModel {

  get channel () {
    return L1ObjectChannel.FB;
  }

  canActive = () => {
    return !this.creatives.some(creative =>
      this.selectedCreatives.includes(creative.id) && creative.effectiveStatus === 'IN_PROCESS');
  }

  canDeactive = () => {
    return !this.creatives.some(creative =>
      this.selectedCreatives.includes(creative.id) && creative.effectiveStatus === 'IN_PROCESS');
  }

  get listFormatters () {
    return fbAdListFormatters;
  }

  get columnsToShow () {
    return _.compact([
      CreativeListColumns.ID,
      CreativeListColumns.PREVIEW,
      CreativeListColumns.STATUS,
      CreativeListColumns.DELIVERY,
      CreativeListColumns.RESULTS,
      CreativeListColumns.CTR,
      CreativeListColumns.IMPRES,
      CreativeListColumns.CLICKS,
      CreativeListColumns.UU,
      CreativeListColumns.TYPE,
      CreativeListColumns.APPROVALSTATUS,
      CreativeListColumns.TOOL
    ]);
  }
}

export class TiktokAdOverviewStateContentModel extends DefaultCreativeOverviewStateContentModel {

  get channel () {
    return L1ObjectChannel.TIKTOK;
  }

  canActive = () => {
    return true;
  }

  canDeactive = () => {
    return true;
  }

  get listFormatters () {
    return tiktokAdListFormatters;
  }

  get columnsToShow () {
    return _.compact([
      CreativeListColumns.ID,
      CreativeListColumns.PREVIEW,
      CreativeListColumns.STATUS,
      CreativeListColumns.DELIVERY,
      CreativeListColumns.RESULTS,
      CreativeListColumns.CTR,
      CreativeListColumns.IMPRES,
      CreativeListColumns.CLICKS,
      CreativeListColumns.UU,
      CreativeListColumns.TYPE,
      CreativeListColumns.APPROVALSTATUS,
      CreativeListColumns.TOOL
    ]);
  }
}
