import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SelectOptions } from 'components/common/commonType';
import { DefaultAdRequestSourceManager, AdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { AddonFeatureManager } from 'core';
import { CreativeType } from 'core/creative/Creative';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import _ from 'lodash';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { ROUTE_PATH } from 'enum/RoutePath';
import { CreativeFormBasicData, CreativeFormData, FormContentModel } from './FlowSteps/SubSteps/FormContent/FormContentModel';
import {
  ComboSummaryModel,
  CreateImageMultipleSummaryModel,
  CreativeSummaryModel,
  HTML5SummaryModel,
  ImageSummaryModel,
  NativeSummaryModel,
  OneForAllDisplaySummaryModel,
  OneForAllVideoSummaryModel,
  PilotTVSummaryModel,
  PPSSummaryModel,
  RetailNativeProductSummaryModel,
  RetailRichMediaProductSummaryModel,
  VideoSummaryModel,
  EdiMaxSummaryModel,
  CustomLayoutSummaryModel,
  PICSummaryModel
} from './FlowSteps/SubSteps/SummaryContent/CreativeSummaryModel';
import { CreativeSetupFlowDataContextType } from './CreativeSetupFlowDataContext';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import {
  CreateCreativeAndBindAdGroupSummaryStepModel,
  CreateCreativeAndBindAdSetSummaryStepModel,
  CreateCreativeAndBindRTBCampaignSummaryStepModel,
  CreateCreativeSummaryStepModel,
  CreativeSummaryStepModel,
  EditCreativeSummaryStepModel
} from './FlowSteps/CreativeSummaryStepModel';
import { CreateImageFormModel, ImageFormModel } from './FlowSteps/SubSteps/FormContent/ImageFormModel';
import { CreativeSetupStepModel, DefaultCreativeSetupStepModel } from './FlowSteps/CreativeSetupStepModel';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { computeChecksumMd5 } from 'utils/Md5Utils';
import { OneForAllDisplayFormModel } from './FlowSteps/SubSteps/FormContent/OneForAllDisplayFormModel';
import { OneForAllVideoFormModel } from './FlowSteps/SubSteps/FormContent/OneForAllVideoFormModel';
import { DefaultOrderManager, OrderManager } from 'core/order/OrderManager';
import { AD_TYPE_MAP_CREATIVE_TYPE, RtbCampaign } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { RetailRichMediaProductFormModel } from './FlowSteps/SubSteps/FormContent/RetailRichMediaProductFormModel';
import { RetailNativeProductFormModel } from './FlowSteps/SubSteps/FormContent/RetailNativeProductFormModel';
import { PilotTVFormModel } from './FlowSteps/SubSteps/FormContent/PilotTVFormModel';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { NativeFormModel } from './FlowSteps/SubSteps/FormContent/NativeFormModel';
import { VideoFormModel } from './FlowSteps/SubSteps/FormContent/VideoFormModel';
import { PPSFormModel } from './FlowSteps/SubSteps/FormContent/PPSFormModel';
import { Html5FormModel } from './FlowSteps/SubSteps/FormContent/Html5FormModel';
import { ComboFormModel } from './FlowSteps/SubSteps/FormContent/ComboFormModel';
import { EdiMaxFormModel } from './FlowSteps/SubSteps/FormContent/EdiMaxFormModel';
import { CtvFormModel } from './FlowSteps/SubSteps/FormContent/CtvFormModel';
import { AdLogo } from 'core/adLogo/AdLogo';
import { CustomLayoutFormModel } from './FlowSteps/SubSteps/FormContent/CustomLayoutFormModel';
import { PICFormModel } from './FlowSteps/SubSteps/FormContent/PICFormModel';

type RedirectData = {
  pathname: string;
  search?: string,
  state?: any;
};
export interface CreativeSetupFlowPageModel {
  readonly type: string;
  readonly state: CreativeSetupFlowState;
  readonly event: UpdateEventListener<CreativeSetupFlowPageModel>;
  readonly tenmaxCategories: Array<SelectOptions>;
  readonly advertisers: Array<SelectOptions>;
  readonly l1ObjectId?: string;
  readonly campaignId?: string;
  readonly orderNumber?: string;
  readonly draftId?: string;
  readonly supportedCreativeType: CreativeType[];
  readonly dataContenxt: CreativeSetupFlowDataContextType;
  readonly canChooseAdvertiser: boolean;
  readonly needSetupLimitation: boolean;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly defaultAdLogo: AdLogo;
  setEnableAdLogo: (enable: boolean) => void;
  setCreative (creative: CreativeFormData): void;
  init (): void;
  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel;
  onUnmount (handler): void;
  setRedirectData (redirectData?: RedirectData): void;
  setFinishedRedirectData (redirectData?: RedirectData): void;
  cancel (): void;
  setUpAdvertiserData (advertiserId, needGetCategory: boolean, needLoadingIndicator?: boolean): Promise<void>;
  getCreativeSetupStepModel (
    currentCreativeType: CreativeType,
    type: string,
    activeTab: number,
    goSubStep: (subStepIndex: number) => void,
    goLast: (() => void) | undefined,
    goNext: () => void,
    addonFeatureManager: AddonFeatureManager,
    registerValidateMethod: (validateMethod) => void
  ): CreativeSetupStepModel;
  addCache (key: string, data: any): void;
  getCache (key: string): any;
}

export type CreativeSetupFlowProps = {
  readonly model: CreativeSetupFlowPageModel;
};

export type CreativeSetupFlowState = {
  readonly loading: boolean;
  readonly redirectData?: RedirectData;
  readonly creative?: CreativeFormData;
  readonly finished: boolean;
  readonly enableAdLogo: boolean;
};

export abstract class DefaultCreativeSetupFlowPageModel implements CreativeSetupFlowPageModel {

  event: FireableUpdateEventListener<CreativeSetupFlowPageModel>;
  loading: boolean;
  redirectData?: RedirectData;
  initCreative: any;
  creative?: CreativeFormData;
  tenmaxCategories: Array<SelectOptions>;
  finished: boolean;
  creativeSetupStepModel?: CreativeSetupStepModel;
  nativeToBannertemplates: { [size: string]: { templatePath?: string, htmlContent?: string } } = {
    '300x250': {},
    '320x100': {},
    '300x600': {}
  };
  uploadedFiles: { [key: string]: string } = {};
  cache: { [key: string]: string } = {};
  enableAdLogo: boolean = false;
  valideCreativeTypes = this.creativeManager.getCreativeTypes();

  constructor (
    public canChooseAdvertiser: boolean,
    public advertisers: Array<SelectOptions>,
    public addonFeatureManager: AddonFeatureManager,
    public defaultAdLogo: AdLogo,
    public campaignId?: string,
    public orderNumber?: string,
    public l1ObjectId?: string,
    public draftId?: string,
    protected adRequestResourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    protected creativeManager: CreativeManager = new DefaultCreativeManager(),
    protected advertiserManager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.event = new FireableUpdateEventListener<CreativeSetupFlowPageModel>();
    this.loading = false;
    this.tenmaxCategories = [];
    this.finished = false;
  }

  abstract get type ();

  abstract init ();

  getInitCreativeType () {
    return CreativeType.ONE_FOR_ALL_DISPLAY;
  }

  getUploadedFileData = async (file: File) => {
    const fileCheckSum = await computeChecksumMd5(file);
    return this.uploadedFiles[fileCheckSum];
  }

  addUploadedFilesData = async (file, url) => {
    const fileCheckSum = await computeChecksumMd5(file);
    this.uploadedFiles[fileCheckSum] = url;
  }

  initNativeBannerTemplates = async () => {
    const templateUrls = await this.creativeManager.getCreativeNativeToBannerTemplate();
    await Promise.all(
      templateUrls.map(async url => {
        try {
          const regex = /Bannersize-Native-(.*)-template/;
          const matches = url.match(regex);
          if (matches && matches[1]) {
            const size = matches[1].replace('-', 'x');
            const response = await fetch(url);
            this.nativeToBannertemplates[size] = {
              templatePath: url,
              htmlContent: await response.text()
            };
          }
        } catch (e) {}
      })
    );
  }

  get dataContenxt (): CreativeSetupFlowDataContextType {
    return {
      creative: this.creative,
      initCreative: this.initCreative,
      advertisers: this.advertisers,
      enableAdLogo: this.enableAdLogo,
      tenmaxCategories: this.tenmaxCategories,
      supportedCreativeType: this.supportedCreativeType,
      setCreative: this.setCreative,
      setFinishedRedirectData: this.setFinishedRedirectData,
      getFormContentModelOfType: this.getFormContentModelOfType.bind(this),
      getSummaryModel: this.getSummaryModel.bind(this)
    };
  }

  get needSetupLimitation () {
    return true;
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.ONE_FOR_ALL_DISPLAY) &&
      result.push(CreativeType.ONE_FOR_ALL_DISPLAY);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.NATIVE) &&
      result.push(CreativeType.NATIVE);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.IMAGE) &&
      result.push(CreativeType.IMAGE);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.VIDEO) &&
      result.push(CreativeType.VIDEO);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.THIRDPARTY) &&
      result.push(CreativeType.THIRDPARTY);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.HTML5) &&
      result.push(CreativeType.HTML5);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.COMBO) &&
      result.push(CreativeType.COMBO);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.ONE_FOR_ALL_VIDEO) &&
      result.push(CreativeType.ONE_FOR_ALL_VIDEO);
    this.addonFeatureManager.isEveryFeaturesEnable(ADDONFEATURE.CREATIVES.RETAIL_RICH_MEDIA_PRODUCT, ADDONFEATURE.CHANNEL.RETAIL_MEDIA) &&
      result.push(CreativeType.RETAIL_RICH_MEDIA_PRODUCT);
    this.addonFeatureManager.isEveryFeaturesEnable(ADDONFEATURE.CREATIVES.RETAIL_NATIVE_PRODUCT, ADDONFEATURE.CHANNEL.RETAIL_MEDIA) &&
      result.push(CreativeType.RETAIL_NATIVE_PRODUCT);
    this.addonFeatureManager.isEveryFeaturesEnable(ADDONFEATURE.CREATIVES.EDIMAX, ADDONFEATURE.CHANNEL.EDIMAX) &&
      result.push(CreativeType.EDIMAX);
    if (this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.PIC)) {
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.PIC_SHORT) &&
        result.push(CreativeType.PIC_SHORT);
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.PIC_LONG) &&
        result.push(CreativeType.PIC_LONG);
    }
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.PILOT_TV) &&
      result.push(CreativeType.PILOT_TV);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.CTV) &&
      result.push(CreativeType.CTV);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.CUSTOM_BOTTOM) &&
      result.push(CreativeType.CUSTOM_BOTTOM);
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.CUSTOM_RECTANGLE) &&
      result.push(CreativeType.CUSTOM_RECTANGLE);
    return result.filter(type => this.valideCreativeTypes.includes(type));
  }

  setEnableAdLogo = (enableAdLogo: boolean) => {
    this.enableAdLogo = enableAdLogo;
    this.updateState(false);
  }

  getCreativeSetupStepModel (
    creativeType: CreativeType,
    type: string,
    activeTab: number,
    goSubStep: (subStepIndex: number) => void,
    goLast: (() => void) | undefined,
    goNext: () => void,
    addonFeatureManager: AddonFeatureManager,
    registerValidateMethod: (validateMethod) => any
  ): CreativeSetupStepModel {
    if (this.creativeSetupStepModel) {
      this.creativeSetupStepModel.updateProps(activeTab);
      return this.creativeSetupStepModel;
    }
    this.creativeSetupStepModel = new DefaultCreativeSetupStepModel(
      creativeType,
      type,
      activeTab,
      goSubStep,
      goLast,
      goNext,
      addonFeatureManager,
      registerValidateMethod,
      this.setEnableAdLogo
    );
    return this.creativeSetupStepModel;
  }

  abstract getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel;

  setCreative = (creative: CreativeFormData) => {
    if (this.event.count === 0) { // already unmount
      return;
    }
    this.creative = creative;
    this.updateState(false);
  }

  get state (): CreativeSetupFlowState {
    return {
      loading: this.loading,
      redirectData: this.redirectData,
      creative: this.creative,
      finished: this.finished,
      enableAdLogo: this.enableAdLogo
    };
  }

  setRedirectData (redirectData?: RedirectData) {
    this.redirectData = redirectData;
  }

  setFinishedRedirectData = (redirectData?: RedirectData) => {
    this.redirectData = redirectData;
    this.finished = true;
    this.updateState(false);
  }

  getFormContentModelOfType (type: CreativeType): FormContentModel | undefined {
    switch (type) {
      case CreativeType.ONE_FOR_ALL_DISPLAY:
        return new OneForAllDisplayFormModel(this, this.nativeToBannertemplates);
      case CreativeType.IMAGE:
        return new ImageFormModel(this);
      case CreativeType.NATIVE:
        return new NativeFormModel(this, this.nativeToBannertemplates);
      case CreativeType.VIDEO:
        return new VideoFormModel();
      case CreativeType.THIRDPARTY:
        return new PPSFormModel();
      case CreativeType.HTML5:
        return new Html5FormModel(this);
      case CreativeType.COMBO:
        return new ComboFormModel();
      case CreativeType.ONE_FOR_ALL_VIDEO:
        return new OneForAllVideoFormModel(this);
      case CreativeType.EDIMAX:
        return new EdiMaxFormModel();
      case CreativeType.PIC_SHORT:
        return new PICFormModel(0, 15);
      case CreativeType.PIC_LONG:
        return new PICFormModel(16, 30);
      case CreativeType.RETAIL_RICH_MEDIA_PRODUCT:
        return new RetailRichMediaProductFormModel(this);
      case CreativeType.RETAIL_NATIVE_PRODUCT:
        return new RetailNativeProductFormModel(this);
      case CreativeType.PILOT_TV:
        return new PilotTVFormModel();
      case CreativeType.CTV:
        return new CtvFormModel();
      case CreativeType.CUSTOM_BOTTOM:
      case CreativeType.CUSTOM_RECTANGLE:
        return new CustomLayoutFormModel(type);
      default:
        return;
    }
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel | undefined {
    const type = creativeBasicFormData.creativeType;
    switch (type) {
      case CreativeType.NATIVE:
        return new NativeSummaryModel(this.type, creativeBasicFormData, this.nativeToBannertemplates);
      case CreativeType.IMAGE:
        return new ImageSummaryModel(creativeBasicFormData);
      case CreativeType.VIDEO:
        return new VideoSummaryModel(creativeBasicFormData);
      case CreativeType.THIRDPARTY:
        return new PPSSummaryModel(creativeBasicFormData);
      case CreativeType.HTML5:
        return new HTML5SummaryModel(creativeBasicFormData);
      case CreativeType.COMBO:
        return new ComboSummaryModel(creativeBasicFormData);
      case CreativeType.ONE_FOR_ALL_DISPLAY:
        return new OneForAllDisplaySummaryModel(creativeBasicFormData);
      case CreativeType.ONE_FOR_ALL_VIDEO:
        return new OneForAllVideoSummaryModel(creativeBasicFormData);
      case CreativeType.EDIMAX:
        return new EdiMaxSummaryModel(creativeBasicFormData);
      case CreativeType.PIC_SHORT:
      case CreativeType.PIC_LONG:
        return new PICSummaryModel(creativeBasicFormData);
      case CreativeType.RETAIL_RICH_MEDIA_PRODUCT:
        return new RetailRichMediaProductSummaryModel(creativeBasicFormData, _.get(this.initCreative.basic, 'typeProperties.productSetId'));
      case CreativeType.RETAIL_NATIVE_PRODUCT:
        return new RetailNativeProductSummaryModel(creativeBasicFormData, _.get(this.initCreative.basic, 'typeProperties.productSetId'));
      case CreativeType.PILOT_TV:
        return new PilotTVSummaryModel(creativeBasicFormData);
      case CreativeType.CTV:
        return new VideoSummaryModel(creativeBasicFormData);
      case CreativeType.CUSTOM_BOTTOM:
      case CreativeType.CUSTOM_RECTANGLE:
        return new CustomLayoutSummaryModel(creativeBasicFormData);
      default:
        return;
    }
  }

  setUpAdvertiserData = async (advertiserId, needSetCategory: boolean, needLoadingIndicator = false) => {
    needLoadingIndicator && this.updateState(true);
    try {
      if (needSetCategory) {
        const category = await this.advertiserManager.getAdvertiserCategory(advertiserId);
        if (this.creative) {
          _.set(this.creative, 'basic.tenmaxCategory', category);
        }
      }
    } catch (e) {}
    needLoadingIndicator && this.updateState(false);
  }

  cancel = () => {
    const campaignParam = this.draftId ? `draftIds=${this.draftId}` : `campaignIds=${this.campaignId}`;
    const redirectPath = _.isNil(this.orderNumber) || _.isNil(this.l1ObjectId) || (_.isNil(this.campaignId) && _.isNil(this.draftId)) ?
      '/creatives' :
      `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}?${campaignParam}&action=manage`;
    this.redirectData = {
      pathname: redirectPath
    };
    this.updateState(false);
  }

  setUpAdLogo = (creative: CreativeFormData, formContentModel?: FormContentModel) => {
    this.enableAdLogo = formContentModel ? formContentModel.isAdLogoSupported(creative.basic) : false;
    if (this.enableAdLogo && _.isNil(creative.basic.adLogo)) {
      creative.basic = {
        ...creative.basic,
        adLogo: this.defaultAdLogo
      };
    }
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectData = undefined;
    this.creative = undefined;
    this.creativeSetupStepModel = undefined;
    this.finished = false;
    this.creativeSetupStepModel = undefined;
    this.uploadedFiles = {};
    this.cache = {};
  }

  addCache (key: string, data: any) {
    this.cache[key] = data;
  }

  getCache (key: string) {
    return this.cache[key];
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class CreateCreativeSetupFlowPageModel extends DefaultCreativeSetupFlowPageModel {

  get type () {
    return 'create';
  }

  async init () {
    this.updateState(true);
    try {
      const advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
      const creativeType = this.getInitCreativeType();
      await this.initNativeBannerTemplates();
      this.tenmaxCategories = await this.adRequestResourceManager.getTenmaxCategories();
      const model = this.getFormContentModelOfType(creativeType);
      this.creative = {
        basic: {
          advertiserId,
          creativeType: creativeType,
          tenmaxCategory: this.tenmaxCategories && this.tenmaxCategories.length > 0 ?
            this.tenmaxCategories[0].value.toString() : '',
          enableNativeBanner: creativeType === CreativeType.NATIVE ? true : false
        },
        limitations: model ? model.getInitLimitations() : {
          include: [],
          exclude: [],
          nonPreferred: [],
          other: [],
          preferred: []
        }
      };

      if (advertiserId) {
        await this.setUpAdvertiserData(advertiserId, true);
      }

      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.setUpAdLogo(this.creative, model);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  getFormContentModelOfType (type: CreativeType): FormContentModel | undefined {
    if (type === CreativeType.IMAGE) {
      return new CreateImageFormModel(this);
    }
    return super.getFormContentModelOfType(type);
  }

  getSummaryModel (creativeBasicFormData: CreativeFormBasicData): CreativeSummaryModel | undefined {
    const type = creativeBasicFormData.creativeType;
    if (type === CreativeType.IMAGE) {
      return new CreateImageMultipleSummaryModel(creativeBasicFormData);
    }
    return super.getSummaryModel(creativeBasicFormData);
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}

export class EditCreativeSetupFlowPageModel extends DefaultCreativeSetupFlowPageModel {

  constructor (
    public creativeId: number,
    ...args: ConstructorParameters<typeof DefaultCreativeSetupFlowPageModel>
  ) {
    super(...args);
  }

  get type () {
    return 'edit';
  }

  async init () {
    this.updateState(true);
    try {
      await this.initNativeBannerTemplates();
      this.tenmaxCategories = await this.adRequestResourceManager.getTenmaxCategories();
      const creativeFromServer = await this.creativeManager.getCreative(this.creativeId);
      await this.setUpAdvertiserData(creativeFromServer.basic.advertiserId, false);
      const contentFormModel = this.getFormContentModelOfType(creativeFromServer.basic.creativeType);
      if (!contentFormModel) {
        this.redirectData = {
          pathname: `/creatives/${creativeFromServer.basic.creativeId}/edit/${ROUTE_PATH.ERROR404}`
        };
        this.updateState(false);
        return;
      }
      await contentFormModel.init();
      this.creative = contentFormModel.getFormModelData(creativeFromServer);
      this.setUpAdLogo(this.creative, contentFormModel);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new EditCreativeSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}

abstract class CreateCreativeFromL2ObjectSetupFlowPageModel extends CreateCreativeSetupFlowPageModel {

  constructor (
    canChooseAdvertiser: boolean,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    defaultAdLogo: AdLogo,
    campaignId?: string,
    orderNumber?: string,
    l1ObjectId?: string,
    draftId?: string,
    adRequestResourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    private orderManager: OrderManager = new DefaultOrderManager()
  ) {
    super(canChooseAdvertiser, advertisers, addonFeatureManager, defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId, adRequestResourceManager);
  }

  abstract get isChannelSupport ();

  abstract get supportedCreativeType ();

  abstract initCampaign ();

  async init () {
    if (!this.orderNumber) {
      return;
    }
    this.updateState(true);
    try {
      await this.initCampaign();
      await this.initNativeBannerTemplates();
      const order = await this.orderManager.getOrder(this.orderNumber);
      this.tenmaxCategories = await this.adRequestResourceManager.getTenmaxCategories();
      const advertiserId = order.advertiserId;
      const creativeType = this.supportedCreativeType[0];
      const model = this.getFormContentModelOfType(creativeType);
      this.creative = {
        basic: {
          advertiserId,
          creativeType,
          tenmaxCategory: this.tenmaxCategories && this.tenmaxCategories.length > 0 ?
            this.tenmaxCategories[0].value.toString() : '',
          enableNativeBanner: creativeType === CreativeType.NATIVE ? true : false
        },
        limitations: model ? model.getInitLimitations() : {}
      };
      await this.setUpAdvertiserData(advertiserId, true);
      _.set(this.creative, 'basic.typeProperties', model ? model.getInitTypeProperties() : undefined);
      this.setUpAdLogo(this.creative, model);
      this.initCreative = JSON.parse(JSON.stringify(this.creative));
    } catch (e) {}
    this.updateState(false);
  }

  abstract getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel;
}

export class CreateCreativeFromRtbCampaignSetupFlowPageModel extends CreateCreativeFromL2ObjectSetupFlowPageModel {

  campaign?: RtbCampaign;

  constructor (
    canChooseAdvertiser: boolean,
    advertisers: Array<SelectOptions>,
    addonFeatureManager: AddonFeatureManager,
    defaultAdLogo: AdLogo,
    campaignId?: string,
    orderNumber?: string,
    l1ObjectId?: string,
    draftId?: string,
    adRequestResourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    private campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    private draftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super(canChooseAdvertiser, advertisers, addonFeatureManager, defaultAdLogo, campaignId, orderNumber, l1ObjectId, draftId, adRequestResourceManager);
  }

  async initCampaign () {
    if (this.draftId) {
      try {
        this.campaign = await this.draftManager.getDraft(this.draftId);
      } catch (e) {}
      return;
    }
    if (!this.campaignId) {
      return;
    }
    try {
      this.campaign = await this.campaignManager.getCampaign(this.campaignId);
    } catch (e) {}
  }

  get isChannelSupport () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.RTB);
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    if (!this.campaign || !this.campaign.basic.adType || !this.isChannelSupport) {
      return result;
    }

    AD_TYPE_MAP_CREATIVE_TYPE[this.campaign.basic.adType].forEach(creativeType => {
      const addonName: any = `option_${creativeType}`;
      this.addonFeatureManager.isFeatureEnable(addonName) &&
        result.push(creativeType);
    });
    return result.filter(type => this.valideCreativeTypes.includes(type));
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeAndBindRTBCampaignSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}

export class CreateCreativeFromRetailMediaCampaignSetupFlowPageModel extends CreateCreativeFromRtbCampaignSetupFlowPageModel {

  get isChannelSupport () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.RETAIL_MEDIA);
  }
}

export class CreateCreativeFromEdiMaxCampaignSetupFlowPageModel extends CreateCreativeFromRtbCampaignSetupFlowPageModel {

  get isChannelSupport () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.EDIMAX);
  }
}

export class CreateCreativeFroPICCampaignSetupFlowPageModel extends CreateCreativeFromRtbCampaignSetupFlowPageModel {

  get isChannelSupport () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.PIC);
  }
}

export class CreateCreativeFromAdSetSetupFlowPageModel extends CreateCreativeFromL2ObjectSetupFlowPageModel {

  async initCampaign () {
    // This is intentional
  }

  get isChannelSupport () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.FB);
  }

  get supportedCreativeType () {
    let result: CreativeType[] = [];
    this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.ONE_FOR_ALL_DISPLAY) &&
      result.push(CreativeType.ONE_FOR_ALL_DISPLAY);
    result.push(CreativeType.ONE_FOR_ALL_VIDEO);
    return result.filter(type => this.valideCreativeTypes.includes(type));
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeAndBindAdSetSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}

export class CreateCreativeFromAdGroupSetupFlowPageModel extends CreateCreativeFromL2ObjectSetupFlowPageModel {

  async initCampaign () {
    // This is intentional
  }

  get isChannelSupport () {
    return this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.TIKTOK);
  }

  get supportedCreativeType () {
    return [CreativeType.ONE_FOR_ALL_VIDEO];
  }

  getCreativeSummaryModel (
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void
  ): CreativeSummaryStepModel {
    return new CreateCreativeAndBindAdGroupSummaryStepModel(
      this.type,
      this.canChooseAdvertiser,
      goLast,
      goStep,
      this.getUploadedFileData,
      this.addUploadedFilesData,
      this.creativeManager,
      this.campaignId,
      this.orderNumber,
      this.l1ObjectId,
      this.draftId
    );
  }
}
