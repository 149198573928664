import {
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { SupportVideoViewsFormModel } from './SupportVideoViewsFormModel';
export interface ThirdPartyRectangleFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractThirdPartyRectangleFormModel extends SupportVideoViewsFormModel
  implements ThirdPartyRectangleFormModel {

  showVideoProgressRadio = true;

  get campaignAdType (): AdType {
    return AdType.THIRD_PARTY_RECTANGLE;
  }
}

export class EditThirdPartyRectangleFormModel extends AbstractThirdPartyRectangleFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateThirdPartyRectangleFormModel extends AbstractThirdPartyRectangleFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
