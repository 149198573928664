import platform_icon_facebook from 'assets/facebook.png';
import platform_icon_instagram from 'assets/instagram.png';
import platform_icon_openRTB from 'assets/openRTB.png';
import platform_icon_audience_network from 'assets/audience_network.png';
import platform_icon_messenger from 'assets/messenger.png';
import platform_icon_tiktok from 'assets/tiktok.png';
import channel_icon_rtb from 'assets/channel_rtb.png';
import channel_icon_meta from 'assets/channel_meta.png';
import channel_icon_tiktok from 'assets/channel_tiktok.png';
import {
  Performance,
  ReportSection,
  NoteSection,
  Platform,
  Group,
  Column,
  TargetKPI,
  Note
} from './FinalReport';
import { OrderType } from 'core/order/Order';
import { L1ObjectChannel } from 'core/l1Object/L1Object';

export const ICON_MAP: {
  [key: string]: {
    src: string,
    size: 'ordinary' | 'small'
  }
} = Object.freeze({
  [Platform.FACEBOOK]: {
    src: platform_icon_facebook,
    size: 'ordinary'
  },
  [Platform.INSTAGRAM]: {
    src: platform_icon_instagram,
    size: 'ordinary'
  },
  [Platform.OPEN_RTB]: {
    src: platform_icon_openRTB,
    size: 'ordinary'
  },
  [Platform.AUDIENCE_NETWORK]: {
    src: platform_icon_audience_network,
    size: 'small'
  },
  [Platform.MESSENGER]: {
    src: platform_icon_messenger,
    size: 'small'
  },
  [Platform.TIKTOK]: {
    src: platform_icon_tiktok,
    size: 'ordinary'
  },
  [L1ObjectChannel.RTB]: {
    src: channel_icon_rtb,
    size: 'small'
  },
  [L1ObjectChannel.RETAIL_MEDIA]: {
    src: channel_icon_rtb,
    size: 'small'
  },
  [L1ObjectChannel.EDIMAX]: {
    src: channel_icon_rtb,
    size: 'small'
  },
  [L1ObjectChannel.PIC]: {
    src: channel_icon_rtb,
    size: 'small'
  },
  [L1ObjectChannel.MESSAGE]: {
    src: channel_icon_rtb,
    size: 'small'
  },
  [L1ObjectChannel.FB]: {
    src: channel_icon_meta,
    size: 'small'
  },
  [L1ObjectChannel.TIKTOK]: {
    src: channel_icon_tiktok,
    size: 'small'
  }
});

export const KPI_COLUMN_MAP: {
  [key: string]: Column
} = Object.freeze({
  [TargetKPI.IMPRESSION]: Column.IMPRESSIONS,
  [TargetKPI.CLICK]: Column.CLICKS,
  [TargetKPI.CONVERSION]: Column.CLICKS,
  [TargetKPI.VIEW]: Column.VIEWS
});

export const REPORT_GROUPS_TEMPLATE = Object.freeze({
  [Performance.OVERALL]: {
    [ReportSection.PLATFORM]: [
      Group.PLATFORM
    ],
    [ReportSection.L1OBJECT]: [
      Group.L1OBJECT
    ]
  },
  [Performance.DEEP_DIVE]: {
    [ReportSection.PLATFORM]: {
      [Platform.OPEN_RTB]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.AUDIENCE_SEGMENT,
        Group.DOMAIN,
        Group.SERVICE_AREA
      ],
      [Platform.FACEBOOK]: [
        Group.PLATFORM,
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.AUDIENCE_SEGMENT,
        Group.SERVICE_AREA
      ],
      [Platform.TIKTOK]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.AUDIENCE_SEGMENT,
        Group.SERVICE_AREA
      ]
    },
    [ReportSection.L1OBJECT]: {
      [L1ObjectChannel.RTB]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.TENMAX_SEGMENT,
        Group.TRAFFIC_CATEGORY,
        Group.SERVICE_AREA
      ],
      [L1ObjectChannel.RETAIL_MEDIA]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.TENMAX_SEGMENT,
        Group.TRAFFIC_CATEGORY,
        Group.SERVICE_AREA
      ],
      [L1ObjectChannel.EDIMAX]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.TENMAX_SEGMENT,
        Group.TRAFFIC_CATEGORY,
        Group.SERVICE_AREA
      ],
      [L1ObjectChannel.PIC]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.TENMAX_SEGMENT,
        Group.TRAFFIC_CATEGORY,
        Group.SERVICE_AREA
      ],
      [L1ObjectChannel.MESSAGE]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.TENMAX_SEGMENT,
        Group.TRAFFIC_CATEGORY,
        Group.SERVICE_AREA
      ],
      [L1ObjectChannel.FB]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.TENMAX_SEGMENT,
        Group.TRAFFIC_CATEGORY,
        Group.SERVICE_AREA
      ],
      [L1ObjectChannel.TIKTOK]: [
        Group.AGE_GROUP,
        Group.GENDER,
        Group.CREATIVE,
        Group.TENMAX_SEGMENT,
        Group.TRAFFIC_CATEGORY,
        Group.SERVICE_AREA
      ]
    },
    [ReportSection.ORDER]: [
      Group.AGE_GROUP,
      Group.GENDER,
      Group.CREATIVE,
      Group.TENMAX_SEGMENT,
      Group.TRAFFIC_CATEGORY,
      Group.SERVICE_AREA
    ],
    [ReportSection.USERS]: [
      Group.AGE_GROUP,
      Group.GENDER,
      Group.AUDIENCE_SEGMENT,
      Group.REGION
    ]
  }
});

export const COLUMNS_TEMPLATE = Object.freeze({
  [Performance.OVERALL]: {
    [ReportSection.PLATFORM]: [
      Column.IMPRESSIONS,
      Column.REACH,
      Column.CLICKS,
      Column.CTR,
      Column.CPC,
      Column.CPM
    ],
    [ReportSection.L1OBJECT]: [
      Column.IMPRESSIONS,
      Column.REACH,
      Column.CLICKS,
      Column.CONVS,
      Column.VIEWS,
      Column.CTR,
      Column.VTR,
      Column.CPM,
      Column.CPC,
      Column.CPA,
      Column.CPV,
      Column.SPEND
    ]
  },
  [Performance.DEEP_DIVE]: {
    [ReportSection.PLATFORM]: [
      Column.IMPRESSIONS,
      Column.REACH,
      Column.CLICKS,
      Column.CTR,
      Column.CPC,
      Column.CPM
    ],
    [ReportSection.USERS]: [
      Column.SALES,
      Column.TRANSACTIONS,
      Column.BASKET_SIZE,
      Column.ITEMS_SOLD,
      Column.NEW_USERS,
      Column.LAPSE_USERS,
      Column.EXISTING_USERS
    ],
    [ReportSection.L1OBJECT]: [
      Column.IMPRESSIONS,
      Column.REACH,
      Column.CLICKS,
      Column.VIEWS,
      Column.CTR,
      Column.VTR,
      Column.CPM,
      Column.CPC,
      Column.CPV
    ],
    [ReportSection.ORDER]: [
      Column.IMPRESSIONS,
      Column.REACH,
      Column.CLICKS,
      Column.VIEWS,
      Column.CTR,
      Column.VTR,
      Column.CPM,
      Column.CPC,
      Column.CPV
    ]
  }
});

export const NOTES_TEMPLATE = Object.freeze({
  [Performance.OVERALL]: {
    [NoteSection.PLATFORM]: [
      Note.ACTUAL_IMPRESSIONS,
      Note.TARGET_IMPRESSIONS,
      Note.REACH,
      Note.CLICKS,
      Note.CTR,
      Note.CPC,
      Note.CPM,
      Note.FACEBOOK,
      Note.BENCHMARKS
    ],
    [NoteSection.ADS_IMPACT]: [
      Note.SALES,
      Note.TRANSACTIONS,
      Note.ITEMS_SOLD,
      Note.NEW_USERS,
      Note.LAPSE_USERS,
      Note.EXISTING_USERS,
      Note.BASKET_SIZE
    ],
    [NoteSection.L1OBJECT]: [
      Note.ACTUAL_IMPRESSIONS,
      Note.TARGET_IMPRESSIONS,
      Note.REACH,
      Note.CLICKS,
      Note.VIEWS,
      Note.CTR,
      Note.VTR,
      Note.CPM,
      Note.CPC,
      Note.CPV
    ]
  },
  [Performance.DEEP_DIVE]: {
    [NoteSection.ADS_IMPACT_USERS]: [
      Note.NEW_USERS,
      Note.LAPSE_USERS,
      Note.EXISTING_USERS
    ],
    [NoteSection.USERS]: [
      Note.SALES,
      Note.TRANSACTIONS,
      Note.ITEMS_SOLD,
      Note.NEW_USERS,
      Note.LAPSE_USERS,
      Note.EXISTING_USERS,
      Note.BASKET_SIZE,
      Note.OTHERS
    ],
    [NoteSection.PLATFORM]: {
      [Platform.FACEBOOK]: [
        Note.IMPRESSIONS,
        Note.REACH,
        Note.CLICKS,
        Note.CTR,
        Note.CPC,
        Note.CPM,
        Note.SERVICE_AREA,
        Note.BENCHMARKS,
        Note.OTHERS
      ],
      [Platform.OPEN_RTB]: [
        Note.IMPRESSIONS,
        Note.REACH,
        Note.CLICKS,
        Note.CTR,
        Note.CPC,
        Note.CPM,
        Note.SERVICE_AREA,
        Note.BENCHMARKS,
        Note.OTHERS
      ],
      [Platform.TIKTOK]: [
        Note.IMPRESSIONS,
        Note.REACH_TIKTOK,
        Note.CLICKS,
        Note.CTR,
        Note.CPC,
        Note.CPM,
        Note.SERVICE_AREA,
        Note.OTHERS
      ]
    },
    [NoteSection.L1OBJECT]: [
      Note.IMPRESSIONS,
      Note.REACH,
      Note.CLICKS,
      Note.VIEWS,
      Note.CTR,
      Note.VTR,
      Note.CPM,
      Note.CPC,
      Note.CPV,
      Note.OTHERS
    ]
  }
});

export const PLATFORM_PRIORITY: string[] = [
  Platform.FACEBOOK,
  Platform.OPEN_RTB,
  Platform.TIKTOK
];

export const L1_OBJECT_PRIORITY: string[] = [
  L1ObjectChannel.RTB,
  L1ObjectChannel.RETAIL_MEDIA,
  L1ObjectChannel.EDIMAX,
  L1ObjectChannel.PIC,
  L1ObjectChannel.MESSAGE,
  L1ObjectChannel.FB,
  L1ObjectChannel.TIKTOK
];

export const ORDER_OVERALL_PRIORITY: {
  [key: string]: string[]
} = {
  [OrderType.GOJEK]: [
    Column.IMPRESSIONS,
    Column.CLICKS,
    Column.CTR,
    Column.CPC,
    Column.CPM
  ],
  [OrderType.TENMAX]: [
    Column.IMPRESSIONS,
    Column.CLICKS,
    Column.CONVS,
    Column.VIEWS,
    Column.CTR,
    Column.VTR,
    Column.CPM,
    Column.CPC,
    Column.CPA,
    Column.CPV,
    Column.SPEND
  ]
};

export const ORDER_COLUMN_PRIORITY: {
  [key: string]: string[]
} = {
  [OrderType.GOJEK]: [
    Column.SALES,
    Column.TRANSACTIONS,
    Column.BASKET_SIZE,
    Column.ITEMS_SOLD,
    Column.NEW_USERS,
    Column.LAPSE_USERS,
    Column.EXISTING_USERS,
    Column.IMPRESSIONS,
    Column.REACH,
    Column.CLICKS,
    Column.CTR,
    Column.CPC,
    Column.CPM
  ],
  [OrderType.TENMAX]: [
    Column.IMPRESSIONS,
    Column.REACH,
    Column.CLICKS,
    Column.CONVS,
    Column.VIEWS,
    Column.CTR,
    Column.VTR,
    Column.CPM,
    Column.CPC,
    Column.CPA,
    Column.CPV,
    Column.SPEND
  ]
};

export const GROUP_LIMIT_MAP: {
  [key: string]: number
} = {
  [Group.CREATIVE]: 5,
  [Group.TENMAX_SEGMENT]: 6,
  [Group.TRAFFIC_CATEGORY]: 5,
  [Group.SERVICE_AREA]: 5
};

export const SUMMABLE_COLUMNS: string[] = [
  Column.IMPRESSIONS,
  Column.RAW_IMPRESSIONS,
  Column.REACH,
  Column.CLICKS,
  Column.VIEWS,
  Column.SALES,
  Column.TRANSACTIONS,
  Column.ITEMS_SOLD,
  Column.NEW_USERS,
  Column.LAPSE_USERS,
  Column.EXISTING_USERS,
  Column.BASKET_SIZE,
  Column.CONVS,
  Column.SPEND
];

export const ORDER_CHART_COLORS: {
  [key: string]: {
    [key: number]: string
  }
} = {
  [OrderType.GOJEK]: {
    0: '#70AF85',
    1: '#B3E283',
    2: '#FF7F3F',
    3: '#FABB51',
    4: '#F7D59C',
    5: '#C8C8C8'
  },
  [OrderType.TENMAX]: {
    0: '#007f90',
    1: '#d1eff1'
  }
};
