import { SelectOptions } from 'components/common/commonType';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useCoreContext } from 'contexts/coreContext';
import { DefaultAgencyManager } from 'core';
import { DefaultProductGroupManager } from 'core/limitation/ProductGroupManager';
import { isSystemAdmin } from 'helper/ActorHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect, useRef } from 'react';

const agencyManager = new DefaultAgencyManager();
const productGroupManager = new DefaultProductGroupManager();

export const FetchAgency: React.FC<{
  adFormat?: string,
  channel?: string,
  onAgencyOptionsFetched: (options: SelectOptions[]) => void
}> = ({
  adFormat,
  channel,
  onAgencyOptionsFetched
}) => {

  const cacheOptions = useRef<{[key: string]: SelectOptions[]}>({});

  const core = useCoreContext();
  const isSysAdmin = core ? isSystemAdmin(core.authenticationManager.actor) : false;

  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    const adFormatChannel = `${adFormat}-${channel}`;
    if (adFormatChannel in cacheOptions.current) {
      onAgencyOptionsFetched(cacheOptions.current[adFormatChannel]);
      return;
    }

    if (!isSysAdmin) {
      return;
    }

    let addonfeatures: string[] = [];
    if (adFormat && channel) {
      let channelAddon = productGroupManager.getRequiredGroupChannelAddonFeaturesOfAdFormatAndChannel(adFormat, channel);
      if (channelAddon) {
        addonfeatures = [...addonfeatures, `channel.${channelAddon}`];
      }
      let creativeAddons = productGroupManager.getRequiredCreativesAddonFeaturesOfAdFormatAndChannel(adFormat, channel);
      if (creativeAddons) {
        addonfeatures = [...addonfeatures, ...creativeAddons.map(addon => `creatives.${addon}`)];
      }
    }

    callAPIs([
      () => agencyManager.getAgenciesOptions(addonfeatures.length > 0 ? addonfeatures.join(',') : undefined)
    ], (agencyOptions) => {
      cacheOptions.current[adFormatChannel] = agencyOptions;
      onAgencyOptionsFetched(agencyOptions);
    });
  }, [adFormat, channel, isSysAdmin, callAPIs, onAgencyOptionsFetched]);

  return loading ? <LoadingIndicator /> : <div />;
};
