import { L1ObjectObjective } from 'core/l1Object/L1Object';
import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';

export interface PICShortFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

abstract class AbstractPICShortFormModel extends DefaultRtbCampaignBasicFormModel
  implements PICShortFormModel {

  showVideoProgressRadio = false;

  get campaignAdType (): AdType {
    return AdType.PIC_SHORT;
  }

  getRtbOptionsMap () {
    return {
      [L1ObjectObjective.AWARENESS]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      },
      [L1ObjectObjective.TRAFFIC]: {},
      [L1ObjectObjective.SALES]: {},
      [L1ObjectObjective.UNSPECIFIED]: {
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      }
    };
  }
}

export class EditPICShortFormModel extends AbstractPICShortFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreatePICShortFormModel extends AbstractPICShortFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
