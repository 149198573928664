import { AgencyDetail, AgencyRebate, Currency, Language, TimeZone } from 'core';
import { AdLogoType } from 'core/adLogo/AdLogo';
import moment from 'moment';
import { toOptions } from 'utils/EnumUtil';
import { defaultCampaignBidPrice } from './defaultCampaignBidPrice';
import { cloneDeep } from 'lodash';

const defaultAgency: AgencyDetail & AgencyRebate = {
  addonFeatures: {
    channel: {
      openRTB: true,
      retail_media: true,
      ediMax: true,
      facebook: true,
      tiktok: true,
      message: true
    },
    creatives: {
      option_1: true,
      option_2: false,
      option_3: false,
      option_4: false,
      option_5: false,
      option_7: false,
      option_8: false,
      option_9: false,
      option_10: false,
      option_11: false,
      option_13: true,
      option_20: true,
      option_21: true,
      option_22: false,
      customizeAdLogo: true
    },
    company: {
      rebate: false,
      allow: false,
      singleOrderBudgetConstraint: false,
      orderBudgetMaximum: false,
      useAgencyProfitOnRebateReport: false,
      showOutComeOnRebateReport: false,
      creditLimit: false,
      allowOrderSettlement: false,
      fixedExchangeRate: false,
      agencyProfit: false,
      advertiserViewSpentInReport: true,
      provideReportToWorkflow: false,
      outdoorAgency: false,
      storedValue: false,
      advAdminInfoRestriction: false,
      directlyApproveCreative: false
    },
    order: {
      tenmax: false,
      gojek: false,
      showOrderExternalType: false
    },
    campaign: {
      bid_weight_setting: false,
      deliveryAmountConstraint: false,
      bidPriceConstraint: false,
      bidPriceBaseVal: false,
      bidPriceCPCMin: false,
      bidPriceCPMMin: false,
      bidPriceCPVMin: false,
      revenueSharing: false,
      budgetDominate: false,
      roadblock: false,
      videoAdViewObjective: false,
      thirdparty_tracking: false,
      retargeting_tracker: false,
      enableOptimizedPrice: false,
      orderAgcProfitSetting: false,
      dynamic_cpm: true
    },
    creative_studio: {
      creative_studio: false
    },
    product: {
      index: false
    },
    conversiontracking: {
      TrackingList: false
    },
    report: {
      viewableCtr: false,
      campaign_dashboard: false,
      booking_calendar: false,
      yahooAd: false,
      closingReport: false,
      restrictFinalReport: false,
      campaignDashboard: false
    },
    monitor: {
      campaignBehindProgress: false,
      systemProfitMonitor: false
    },
    limitation: {
      country: false,
      spaceType: false,
      device: false,
      target_audience: false,
      adexchange: false,
      asiamax_space: false,
      domain: false,
      space_category: false,
      tenmax_audience: false,
      product_segment: false,
      city: false,
      gender: false,
      age: false,
      carrier: false,
      enablePMPDeal: false,
      keyword: false,
      unicornkeywords: false,
      content_language: false,
      content_category: false
    },
    campaignLimitation: {
      country: true,
      spaceType: true,
      device: true,
      target_audience: true,
      adexchange: true,
      domain: true,
      city: true,
      enablePMPDeal: true
    }
  },
  addonProps: {
    maxOrderProfit: 0,
    outdoorAdImpresMultiply: 1,
    orderBudgetMaximum: 10000,
    creditLimit: 0,
    fixedExchangeRate: 0,
    outdoorMachineCount: 0,
    sysProfitMonitorPercent: 0,
    campaignBidPrice: cloneDeep(defaultCampaignBidPrice)
  },
  bidPriceBase: {
    DISPLAY: 1,
    COMBO: 17,
    VIDEO: 90,
    THIRD_PARTY: 112.5,
    THIRD_PARTY_BOTTOM: 112.5,
    THIRD_PARTY_RECTANGLE: 112.5
  },
  logoUrl: '',
  agcDataPercent: 0,
  agcPercent: 0,
  agencyId: 0,
  comComment: '',
  companyName: '',
  currency: Currency.NTD,
  defaultCountry: [],
  defaultCountryOp: 'inc',
  defaultSpace: [],
  defaultSpaceOp: 'inc',
  interstitial: 0,
  oem: false,
  language: Language.ZHTW,
  limitAdx: [{
    label: 'DoubleClick',
    value: 'DoubleClick'
  },{
    label: 'AsiaMax',
    value: 'AsiaMax'
  },{
    label: 'BidSwitch',
    value: 'BidSwitch'
  }],
  limitAdxOp: 'inc',
  limitPublisher: [],
  limitPublisherCountry: [],
  noLimitSelfBuyStatus: false,
  priority: 1,
  segmentCountry: [],
  segmentLabel: [],
  selfBuy: false,
  sysDataPercent: 0,
  sysPercent: 0,
  sysPercentIn: 0,
  sysPercentOut: 0,
  targetBudgetMinimum: 0,
  campaignBudgetMinimum: 0,
  taxPercent: 0,
  timeZone: TimeZone.TAIPEI,
  adLogo: {
    type: AdLogoType.NULL
  },
  logoData: {},
  currentRate: 0,
  futureRate: 0,
  effectDate: moment().startOf('day').format('YYYY-MM-DD')
};

const data = {
  defaultAgency,
  timeZoneOptions: toOptions(TimeZone),
  currencyOptions: toOptions(Currency),
  languageOptions: toOptions(Language)
};

export default data;
